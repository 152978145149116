import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'print_jobs'
  | 'production'
  | 'print_delivery_notes'
  | 'print_list'
  | 'print_lists'
  | 'orders'
  | 'pre_production_orders'
  | 'all_print_lists'
  | 'active_print_lists'
  | 'inactive_print_lists'
  | 'edit_print_list'
  | 'forwarding_list'
  | 'cross_table_report'
  | 'labels'
  | 'dough_list'
  | 'orders_over_bakery2b'
  | 'orders_over_cashassist'
  | 'report_data_was_exported_to_LabelAssist'
  | 'report_data_was_exported_to_SmartScale'
  | 'activated'
  | 'order_type'
  | 'delivery_note_group'
  | 'printed'
  | 'date_time'
  | 'print_list_not_loaded'
  | 'deleting_print_list'
  | 'print_list_successfully_deleted'
  | 'delete_print_list_confirmation'
  | 'tab_not_loaded'
  | 'set_up'
  | 'production_report_type'
  | 'labels_labelAssist'
  | 'dough_list_smartScale'
  | 'form_type'
  | 'form'
  | 'partial_delivery'
  | 'show_production_description'
  | 'automatic_mode_of_cross_table_report'
  | 'show_individual_texts'
  | 'consider_only_order_positions_with_individual_text'
  | 'display_linked_articles_with_indentation'
  | 'do_default_dough_splitting'
  | 'display_delivery_splitting_parts'
  | 'sorting_type'
  | 'pre_production_in_days'
  | 'show_semi_products_details'
  | 'tour'
  | 'tour_type'
  | 'need_signature'
  | 'standard_tour'
  | 'sat_tour'
  | 'sun_tour'
  | 'quantity_beginning_of_the_day'
  | 'print_all_delivery_notes_of_this_tour'
  | 'customer_sorting'
  | 'article_sorting'
  | 'print_list_settings'
  | 'additional_settings'
  | 'print_list_availability'
  | 'filters_setup'
  | 'edit_print_list_saved'
  | 'edit_print_list_not_saved'
  | 'request_error_message'
  | 'try_preview_again'
  | 'try_again'
  | 'cross_table_setup'
  | 'cross_table_config_edit'
  | 'tour_list_setup'
  | 'tour_list_config_edit'
  | 'article_list_filter'
  | 'customer_list_filter'
  | 'create_print_list'
  | 'copy_print_list'
  | 'no_data_no_preview'
  | 'stock_of_freezer'
  | 'article'
  | 'article_other'
  | 'legend_over_stock'
  | 'legend_normal_stock'
  | 'legend_under_stock'
  | 'active'
  | 'type_of_goods'
  | 'storage_locations'
  | 'stock_min_qty'
  | 'stock_max_qty'
  | 'stock_actual_qty'
  | 'stock_qty'
  | 'set_quantity'
  | 'stock_of_freezer_saved'
  | 'stock_of_freezer_saved_not_saved'
  | 'group_name'
  | 'split_no'
  | 'customer'
  | 'customer_list'
  | 'customer_or_customer_list'
  | 'copy_settings_from'
  | 'copy'
  | 'filter_by'
  | 'manage_versions'
  | 'display_selected_version'
  | 'compare_selected_versions'
  | 'product_no'
  | 'products'
  | 'manage_quantity_of_produced_articles';

const t = LocalizationHelper<Keys>('production');

export const productionDe = t({
  print_jobs: 'Druckaufträge',
  production: 'Produktion',
  print_delivery_notes: 'Lieferscheine drucken',
  print_list: 'Druckliste',
  print_lists: 'Drucklisten',
  orders: 'Bestellungen',
  quantity_beginning_of_the_day: 'Menge (am Anfang des Tages)',
  legend_over_stock: 'Maximum überschritten',
  legend_normal_stock: 'Bestand normal',
  legend_under_stock: 'Minimum unterschritten',
  pre_production_orders: 'Bestellungen für Vorproduktion',
  all_print_lists: 'Alle Drucklisten',
  active_print_lists: 'Aktive Drucklisten',
  inactive_print_lists: 'Inaktive Drucklisten',
  edit_print_list: 'Drucklisten bearbeiten',
  stock_of_freezer: 'TK-Bestand',
  forwarding_list: 'Versand Spediliste',
  cross_table_report: 'Versand Cross Bericht',
  labels: 'Etiketten (LabelAssist)',
  dough_list: 'Teiglisten (smartScale)',
  orders_over_bakery2b: 'Bestellungen über bakery2b.com',
  orders_over_cashassist: 'Bestellungen über CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist: 'Report-Daten wurden exportiert LabelAssist',
  report_data_was_exported_to_SmartScale: 'Report-Daten wurden exportiert Smartscale',
  activated: 'Aktiviert',
  order_type: 'Bestelltyp',
  article_list_filter: 'Artikel Filter',
  customer_list_filter: 'Kunden Filter',
  delivery_note_group: 'Lieferscheingruppe',
  printed: 'Gedruckt',
  date_time: 'Datum/Zeit',
  print_list_not_loaded: 'Druckliste nicht geladen',
  deleting_print_list: 'Druckliste wird gelöscht...',
  print_list_successfully_deleted: 'Druckliste erfolgreich gelöscht',
  delete_print_list_confirmation: 'Sind Sie sicher, dass Sie die Druckliste löschen möchten?',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  set_up: 'Aufstellen',
  labels_labelAssist: 'Etiketten (LabelAssist)',
  dough_list_smartScale: 'Teiglisten (smartScale)',
  production_report_type: 'Art des Produktionsberichts',
  form_type: 'Formulartyp',
  form: 'Formular',
  partial_delivery: 'Anfahrt',
  show_production_description: 'Herstellungsbeschrieb anzeigen',
  automatic_mode_of_cross_table_report: 'Automatikbetrieb des Kreuztabellenberichts',
  show_individual_texts: 'Individueller Text anzeigen',
  consider_only_order_positions_with_individual_text: 'Nur Artikel mit individuellen Text',
  display_linked_articles_with_indentation: 'Verknüpfte Artikel mit Einzug (->) darstellen',
  do_default_dough_splitting: 'Automatisches Teigsplitting',
  display_delivery_splitting_parts: 'Teillieferungen anzeigen',
  sorting_type: 'Sortiertyp',
  pre_production_in_days: 'Vorproduktion (Tage)',
  show_semi_products_details: 'Halbfabrikate detailiert zeigen',
  tour: 'Tour',
  tour_type: 'Tourtyp',
  need_signature: 'Mit Unterschrift',
  standard_tour: 'Standard Tour',
  print_all_delivery_notes_of_this_tour: 'Alle Lieferschein dieses Tour ausdrucken',
  customer_sorting: 'Sortierung Kunde',
  sat_tour: 'Sa. Tour',
  sun_tour: 'So. Tour',
  article_sorting: 'Sortierung Artikel',
  print_list_settings: 'Druckliste Einstellungen',
  additional_settings: 'Zusätzliche Einstellungen',
  print_list_availability: 'Druckliste Verfügbarkeit',
  filters_setup: 'Filterkonfiguration',
  edit_print_list_saved: 'Druckliste bearbeiten gespeichert',
  edit_print_list_not_saved: 'Druckliste bearbeiten nicht gespeichert',
  request_error_message: 'Leider ist bei der Bearbeitung Ihrer Anfrage ein Fehler aufgetreten.',
  try_preview_again: 'Bitte versuchen Sie erneut, eine Vorschau des Dokuments anzeigen.',
  try_again: 'Versuchen Sie es erneut',
  cross_table_setup: 'Kreuztabelle Einstellung',
  cross_table_config_edit: 'Kreuztabellen-Konfiguration bearbeiten',
  tour_list_setup: 'Tourenliste Einstellung',
  tour_list_config_edit: 'Tourenlisten-Konfiguration bearbeiten',
  create_print_list: 'Druckliste erstellen',
  copy_print_list: 'Druckliste kopieren',
  no_data_no_preview: 'Vorschau ist nicht verfügbar. Keine Daten für den Bericht.',
  article: 'Artikel',
  article_other: 'Artikel',
  active: 'Aktiv',
  type_of_goods: 'Warentyp',
  storage_locations: 'Lagerorte',
  stock_min_qty: 'Min. Bestand',
  stock_max_qty: 'Max. Bestand',
  stock_actual_qty: 'Aktueller Bestand',
  stock_qty: 'Bestand',
  set_quantity: 'Menge einstellen',
  stock_of_freezer_saved: 'TK-Bestand bearbeiten gespeichert',
  stock_of_freezer_saved_not_saved: 'TK-Bestand bearbeiten nicht gespeichert',
  group_name: 'Gruppenname',
  split_no: 'Split-Nr.',
  customer: 'Kunde',
  customer_list: 'Kundenliste',
  customer_or_customer_list: 'Kunde / Kundenliste',
  copy_settings_from: 'Einstellungen kopieren von',
  copy: 'Kopieren',
  filter_by: 'Filtern nach',
  manage_versions: 'Versionen verwalten',
  display_selected_version: 'Ausgewählte Version anzeigen',
  compare_selected_versions: 'Zwei Versionen vergleichen (Backzettel mit Differenz anzeigen)',
  product_no: 'Produkt-Nr.',
  products: 'Produkte',
  manage_quantity_of_produced_articles: 'Menge der produzierten Artikel verwalten',
});

export const productionEn = t({
  print_jobs: 'Print jobs',
  production: 'Production',
  print_delivery_notes: 'Print delivery notes',
  print_list: 'Print list',
  print_lists: 'Print lists',
  orders: 'Orders',
  quantity_beginning_of_the_day: 'Quantity (beginning of the day)',
  legend_over_stock: 'Over stock',
  legend_normal_stock: 'Normal stock',
  legend_under_stock: 'Under stock',
  pre_production_orders: 'Orders for pre-production',
  all_print_lists: 'All print lists',
  active_print_lists: 'Active print lists',
  inactive_print_lists: 'Inactive print lists',
  edit_print_list: 'Edit print list',
  stock_of_freezer: 'Stock of freezer',
  forwarding_list: 'Forwarding list',
  cross_table_report: 'Shipping cross report',
  labels: 'Labels (LabelAssist)',
  dough_list: 'Dough list (smartScale)',
  orders_over_bakery2b: 'Orders over bakery2b.com',
  orders_over_cashassist: 'Orders over CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist: 'Report data was exported to LabelAssist',
  report_data_was_exported_to_SmartScale: 'Report data was exported to smartScale',
  activated: 'Activated',
  order_type: 'Order type',
  article_list_filter: 'Article filter',
  customer_list_filter: 'Customer filter',
  delivery_note_group: 'Delivery note group',
  printed: 'Printed',
  date_time: 'Date/Time',
  print_list_not_loaded: 'Print list not loaded',
  deleting_print_list: 'Deleting print list...',
  print_list_successfully_deleted: 'Print list successfully deleted',
  delete_print_list_confirmation: 'Are you sure you want to delete the print list?',
  tab_not_loaded: 'Data for tab not loaded!',
  set_up: 'Set up',
  labels_labelAssist: 'Labels (LabelAssist)',
  dough_list_smartScale: 'Dough list (smartScale)',
  production_report_type: 'Production report type',
  form_type: 'Form type',
  form: 'Form',
  partial_delivery: 'Partial delivery',
  show_production_description: 'Display production description',
  automatic_mode_of_cross_table_report: 'Automatic mode of cross-table report',
  show_individual_texts: 'Display individual descriptions',
  consider_only_order_positions_with_individual_text: 'Only articles with individual description',
  display_linked_articles_with_indentation: 'Make identation (->) for linked articles',
  do_default_dough_splitting: 'Do default dough splitting',
  display_delivery_splitting_parts: 'Display delivery splitting parts',
  sorting_type: 'Sorting type',
  pre_production_in_days: 'Pre-production (days)',
  show_semi_products_details: 'Display semi-products details',
  tour: 'Tour',
  tour_type: 'Tour type',
  need_signature: 'Need signature',
  standard_tour: 'Standard tour',
  print_all_delivery_notes_of_this_tour: 'Print all delivery notes of this tour',
  customer_sorting: 'Customer sorting',
  sat_tour: 'Sat.tour',
  sun_tour: 'Sun. tour',
  article_sorting: 'Article sorting',
  print_list_settings: 'Print list settings',
  additional_settings: 'Additional settings',
  print_list_availability: 'Print list availability',
  filters_setup: 'Filters setup',
  edit_print_list_saved: 'Edit print list saved',
  edit_print_list_not_saved: 'Edit print list not saved',
  request_error_message: 'Sorry, we encountered an error while processing your request.',
  try_preview_again: 'Please try to preview the document again.',
  try_again: 'Try again',
  cross_table_setup: 'Cross-table setup',
  cross_table_config_edit: 'Edit cross-table configuration',
  tour_list_setup: 'Tour list setup',
  tour_list_config_edit: 'Edit tour list configuration',
  create_print_list: 'Create print list',
  copy_print_list: 'Copy print list',
  no_data_no_preview: 'Preview is not available. No data for report.',
  article: 'Article',
  article_other: 'Articles',
  active: 'Active',
  type_of_goods: 'Type of goods',
  storage_locations: 'Storage locations',
  stock_min_qty: 'Min. stock quantity',
  stock_max_qty: ' Max. stock quantity',
  stock_actual_qty: 'Current stock quantity',
  stock_qty: 'Stock quantity',
  set_quantity: 'Set quantity',
  stock_of_freezer_saved: 'Stock of freezer saved',
  stock_of_freezer_saved_not_saved: 'Stock of freezer not saved',
  group_name: 'Group name',
  split_no: 'Split No.',
  customer: 'Customer',
  customer_list: 'Customer list',
  customer_or_customer_list: 'Customer / Customer list',
  copy_settings_from: 'Copy settings from',
  copy: 'Copy',
  filter_by: 'Filter by',
  manage_versions: 'Manage versions',
  display_selected_version: 'Display selected version',
  compare_selected_versions: 'Compare two versions (display backing list with difference)',
  product_no: 'Product-No.',
  products: 'Products',
  manage_quantity_of_produced_articles: 'Manage quantity of produced articles',
});
