/* eslint-disable @typescript-eslint/no-unused-vars */
import { t } from 'i18next';
import {
  C_Production_Article_Sorting_Kind,
  C_Report,
  C_Report_Group,
} from '../../../../../../../graphql/generatedModel';
import { EditPrintListGeneralTabRes } from '../../../../../services/editPrintList.service';
import { GridRowParams } from '@mui/x-data-grid-premium';
import {
  DictCustomerListsRes,
  DictCustomersRes,
} from '../../../../../../../shared/services/configsData/configsData.service';

export const extractGeneralTabData = (
  data: EditPrintListGeneralTabRes,
  fieldName?: string,
): Partial<EditPrintListGeneralTabRes> => {
  const {
    id,
    isShowSemiProductsDetails,
    tourplanId,
    isPrintDeliveryNotesForTourplan,
    articlesSortingKindId,
    addDaysForPreProduction,
    tourplanDay,
    customersSortingKindId,
    crosstableEasyModeSplitNoFilter,
    isNeedSignatureOnForwardingList,
    isAvailableMon,
    isAvailableTue,
    isAvailableWed,
    isAvailableThu,
    isAvailableFri,
    isAvailableSat,
    isAvailableSun,
    isShowProductionDescription,
    isShowIndividualTexts,
    isOnlyPositionsWithIndividualText,
    isHighlightArticlesFromProductionCalc,
    isSplitDoughBeforePrint,
    isShowDeliverySplitting,
    isActive,
    name,
    reportId: initialReportId,
    reportGroupId,
    isCrosstableEasyMode,
    crosstableConfigurationData = [],
    tourplanConfigurationData = [],
    orderTypesFilter,
    customerListsFilter,
    articleListsFilter,
  } = data || {};
  let reportId = initialReportId;

  if (fieldName && fieldName === 'reportGroupId') {
    reportId =
      reportGroupId === C_Report_Group.RG3_ARTICLE ||
      reportGroupId === C_Report_Group.RG11_PRODUCTION_SMARTSCALE
        ? getReportIdByGroup(reportGroupId)
        : null;
  }

  const isBakingListReport = [
    C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS,
    C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED,
    C_Report.R26_BAKING_LIST_WITH_RECIPE_A4,
    C_Report.R27_BAKING_LIST_WITH_RECIPE_A5,
    C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE,
    C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS,
  ].includes(reportId as C_Report);
  const isTourListReport = reportId === C_Report.R32_TOURLIST;
  const isForwardingListCustomerArticlesReport = [
    C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES,
    C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE,
  ].includes(reportId as C_Report);
  const isProductionGroupReport = [
    C_Report_Group.RG1_PRODUCTION_PRODUCTION,
    C_Report_Group.RG11_PRODUCTION_SMARTSCALE,
  ].includes(reportGroupId as C_Report_Group);
  const isCrossTableReport = reportGroupId === C_Report_Group.RG9_PRODUCTION_CROSSTABLE;
  const isTourListOrForwardingListReport =
    isTourListReport || isForwardingListCustomerArticlesReport;
  const isNotSpecialReportGroupOrId =
    reportGroupId !== C_Report_Group.RG11_PRODUCTION_SMARTSCALE &&
    reportId !== C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION &&
    reportId !== C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS;

  let fields: Partial<EditPrintListGeneralTabRes> = {
    ...(reportId === C_Report.R26_BAKING_LIST_WITH_RECIPE_A4 && { isShowProductionDescription }),
    ...(isCrossTableReport && !isCrosstableEasyMode && { crosstableConfigurationData }),
    ...(isTourListReport && { tourplanConfigurationData }),
    ...(isTourListReport && { tourplanId }),
    ...(isTourListReport && { isPrintDeliveryNotesForTourplan }),
    ...(!isTourListReport && isNotSpecialReportGroupOrId && { articlesSortingKindId }),
    ...(isCrossTableReport && { isCrosstableEasyMode }),
    ...(isProductionGroupReport && { addDaysForPreProduction }),
    ...(isBakingListReport && { isShowSemiProductsDetails }),
    ...(isTourListOrForwardingListReport && { tourplanDay }),
    ...(reportGroupId === C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS &&
      reportId !== C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION && {
        isNeedSignatureOnForwardingList,
      }),
    ...(isCrossTableReport &&
      isCrosstableEasyMode && {
        customersSortingKindId,
        crosstableEasyModeSplitNoFilter: Boolean(crosstableEasyModeSplitNoFilter)
          ? crosstableEasyModeSplitNoFilter
          : '0',
      }),
    orderTypesFilter,
    customerListsFilter,
    articleListsFilter,
    isShowIndividualTexts,
    isOnlyPositionsWithIndividualText,
    isHighlightArticlesFromProductionCalc,
    isSplitDoughBeforePrint,
    isShowDeliverySplitting,
    isAvailableMon,
    isAvailableTue,
    isAvailableWed,
    isAvailableThu,
    isAvailableFri,
    isAvailableSat,
    isAvailableSun,
    reportId,
    reportGroupId,
    isActive,
    name,
    id,
  };

  if (fieldName) {
    const updateArticlesSortingKindId = () => {
      const options = getProductionSortingOptions(
        reportGroupId as C_Report_Group,
        reportId as C_Report,
      );
      return articlesSortingKindId &&
        !options.some((option) => option.id === articlesSortingKindId) &&
        (reportGroupId !== null || reportId !== null)
        ? getDefaultProductionSortingOption(reportGroupId as C_Report_Group)
        : articlesSortingKindId;
    };

    if (fieldName.includes('customerIndicator')) {
      const indexMatch = fieldName.match(/crosstableConfigurationData\.(\d+)\.customerIndicator/);
      const index = indexMatch ? parseInt(indexMatch[1], 10) : -1;
      if (index !== -1 && index < crosstableConfigurationData.length) {
        const updatedCrosstableConfigurationData = [...crosstableConfigurationData];
        const additionalSettings = { categoryCustomerId: null, categoryCustomerListId: null };
        updatedCrosstableConfigurationData[index] = {
          ...updatedCrosstableConfigurationData[index],
          ...additionalSettings,
        };

        fields = {
          ...fields,
          crosstableConfigurationData: updatedCrosstableConfigurationData,
        };
      }
    }
    switch (fieldName) {
      case 'reportGroupId':
        fields = {
          ...fields,
          ...(isTourListReport && { tourplanId }),
          ...(!isTourListReport &&
            isNotSpecialReportGroupOrId && {
              articlesSortingKindId: updateArticlesSortingKindId(),
            }),
        };

        break;
      case 'reportId':
        fields = {
          ...fields,
          ...(isTourListReport && {
            tourplanId: reportId === C_Report.R32_TOURLIST ? null : tourplanId,
          }),
          ...(!isTourListReport &&
            isNotSpecialReportGroupOrId && {
              articlesSortingKindId: updateArticlesSortingKindId(),
            }),
        };
        break;
    }
  }
  return fields;
};

export const getProductionSortingOptions = (reportGroupId: C_Report_Group, reportId: C_Report) => {
  const articleSortingKinds = Object.values(C_Production_Article_Sorting_Kind).map((item) => ({
    id: item,
    label: t(`enums.${C_Production_Article_Sorting_Kind[item]}`),
  }));

  if (reportGroupId === C_Report_Group.RG3_ARTICLE) {
    return articleSortingKinds.filter(
      (item) =>
        item.id === C_Production_Article_Sorting_Kind.PASK2_ARTICLE_NO ||
        item.id === C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO,
    );
  }

  let filteredOptions = articleSortingKinds.filter(
    (item) =>
      item.id !== C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO,
  );

  if (
    reportId === C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES ||
    reportId === C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE
  ) {
    filteredOptions = filteredOptions.filter(
      (item) =>
        item.id !== C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO,
    );
  } else if (reportId === C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS) {
    filteredOptions = filteredOptions.filter(
      (item) =>
        item.id !== C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO &&
        item.id !== C_Production_Article_Sorting_Kind.PASK8_DELIVERY_TIME,
    );
  }

  return filteredOptions;
};

export const getDefaultProductionSortingOption = (reportGroupId: C_Report_Group) => {
  return reportGroupId === C_Report_Group.RG3_ARTICLE
    ? C_Production_Article_Sorting_Kind.PASK2_ARTICLE_NO
    : C_Production_Article_Sorting_Kind.PASK1_DOUGH_ARTICLE_NO;
};

export const getReportIdByGroup = (reportGroupId: C_Report_Group) => {
  const reportIdMap: { [key: string]: C_Report } = {
    [C_Report_Group.RG3_ARTICLE]: C_Report.R12_LABEL,
    [C_Report_Group.RG11_PRODUCTION_SMARTSCALE]: C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE,
  };
  return reportIdMap[reportGroupId] || null;
};

export const getDataForRecord = (
  fieldName: keyof EditPrintListGeneralTabRes,
  extractedFields: EditPrintListGeneralTabRes,
) => {
  if (
    fieldName === 'reportGroupId' &&
    (extractedFields[fieldName] === C_Report_Group.RG3_ARTICLE ||
      extractedFields[fieldName] === C_Report_Group.RG11_PRODUCTION_SMARTSCALE)
  ) {
    return {
      reportId: getReportIdByGroup(extractedFields[fieldName] as C_Report_Group),
    };
  } else if (fieldsToTransform.includes(fieldName)) {
    const { articleListsFilter, customerListsFilter, orderTypesFilter } = extractedFields || {};
    return {
      articleListsFilter,
      customerListsFilter,
      orderTypesFilter,
    };
  } else if (fieldName.startsWith('crosstableConfigurationData')) {
    const { crosstableConfigurationData } = extractedFields || {};
    return {
      crosstableConfigurationData:
        crosstableConfigurationData?.map(({ customerIndicator, id, ...settings }) => ({
          ...settings,
        })) || [],
    };
  } else if (fieldName.startsWith('tourplanConfigurationData')) {
    const { tourplanConfigurationData } = extractedFields || {};
    return {
      tourplanConfigurationData:
        tourplanConfigurationData?.map(
          ({
            id,
            ...settings
          }: {
            id?: string;
          } & EditPrintListGeneralTabRes['tourplanConfigurationData'][number]) => ({
            ...settings,
            categoryName: settings?.categoryName === null ? '' : settings?.categoryName,
          }),
        ) || [],
    };
  }

  return { [fieldName]: extractedFields[fieldName] };
};

export const fieldsToTransform: Array<keyof EditPrintListGeneralTabRes> = [
  'articleListsFilter',
  'customerListsFilter',
  'orderTypesFilter',
];

export const checkIfAllFiltersChecked = (
  articleListsFilter: EditPrintListGeneralTabRes['articleListsFilter'],
  customerListsFilter: EditPrintListGeneralTabRes['customerListsFilter'],
  orderTypesFilter: EditPrintListGeneralTabRes['orderTypesFilter'],
) => {
  const combinedFilters = [...articleListsFilter, ...customerListsFilter, ...orderTypesFilter];
  return combinedFilters.some((item) => !item.checked);
};

export const getCustomerOrListId = (
  row: GridRowParams['row'],
  customersOptions: DictCustomersRes,
  customerListsOptions: DictCustomerListsRes,
) => {
  if (row.categoryCustomerId) {
    const customer = customersOptions.find((option) => option.id === row.categoryCustomerId);
    if (customer) return `${customer.customerNo} ${customer.internalOrFullName}`;
  } else if (row.categoryCustomerListId) {
    const customerList = customerListsOptions.find(
      (option) => option.id === row.categoryCustomerListId,
    );
    if (customerList) return customerList.label;
  }

  return null;
};
