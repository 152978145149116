import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { CircularProgress, IconButton, Stack, styled, Typography } from '@mui/material';
import { GridRenderCellParams, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Table, TypeTable } from '../../../../../../shared/components/table/table.component.tsx';
import {
  Column,
  Footer,
} from '../../../../../../shared/components/table/components/common.components.tsx';
import {
  IPrintJobsDetailsState,
  IProductionTabDataRes,
  printJobsDetailsState,
} from '../../../states/printJobsDetails.state.ts';
import { C_Report } from '../../../../../../graphql/generatedModel.ts';
import {
  ExportLabelAssistRes,
  ExportSmartScaleRes,
  productionReportService,
} from '../../../../services/productionReport.service.ts';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  IStatusWorkerState,
  ReportPreviewStatus,
  statusWorkerState,
} from '../../../states/statusWorker/statusWorker.state.ts';
import { PreviewIcon, PreviewStatusIcon } from './PreviewStatusIcon.tsx';
import { VersionStatusIcon } from './VersionStatusIcon.tsx';
import { ProductionListRes } from '../../../../services/printJobs.service.ts';
import { workerService } from '../../../states/statusWorker/workerService.service.ts';
import { Check as CheckIcon, FactCheck, Print as PrintIcon } from '@mui/icons-material';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler.ts';
import { VersionsPopup } from '../popups/versions.popup.tsx';
import {
  IVersionsWorkerState,
  reportIdsForVersioning,
  ReportVersionStatus,
  versionsWorkerState,
} from '../../../states/versionsWorker/versionsWorker.state.ts';
import { useLoaderData } from 'react-router-dom';
import { IPrintJobsLoader } from '../../../../loaders/printJobs/printJobs.loader.ts';

export const ProductionList: FC<IProductionListProps> = ({
  data,
  previewListStatus,
  previewAllStatus,
  versions,
  onOpenMerged,
  datePeriod,
  loading,
}) => {
  const { canWorkWithVersionsInProduction } = useLoaderData() as IPrintJobsLoader;
  const { productionList, countOrders, countOrderPreProduction, allProductionListLength, params } =
    data || {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isExportInProcess, setIsExportInProcess] = useState<boolean>(false);
  const [listAction, setListAction] = useState<IPrintJobsDetailsState['action']>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<ProductionListRes[number] | undefined>();
  const versionsPopup = useMemo(() => new VersionsPopup(), []);

  useEffect(() => {
    const subPrintJobsDetailsState = printJobsDetailsState.sub
      .state()
      .subscribe(({ action, dataTabs }) => {
        const productionTabData = dataTabs.production as IProductionTabDataRes;
        setSelectedRecord(productionTabData.selectedRecord);
        setListAction(action);
      });
    const exportLabelAssist = productionReportService.sub
      .exportLabelAssist()
      .pipe(
        responseHandler<ExportLabelAssistRes>({
          errorReturnType: false,
        }),
      )
      .subscribe(() => handleExportResult('labelAssist'));
    const exportSmartScale = productionReportService.sub
      .exportSmartScale()
      .pipe(
        responseHandler<ExportSmartScaleRes>({
          errorReturnType: false,
        }),
      )
      .subscribe(() => {
        handleExportResult('smartScale');
      });

    return () => {
      subPrintJobsDetailsState.unsubscribe();
      exportLabelAssist.unsubscribe();
      exportSmartScale.unsubscribe();
      versionsPopup.stream.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      listAction === undefined ||
      listAction === 'productionList' ||
      listAction === 'filter' ||
      listAction === 'selectDatePeriod' ||
      listAction === 'search'
    ) {
      const statusList = productionList.reduce<IStatusWorkerState['list']>((outArr, report) => {
        const { id, reportId, isActive } = report;
        const isActionPreview =
          reportId && printJobsDetailsState.helpers.isReportForPreview(reportId);
        if (isActionPreview) {
          const isEnabled = printJobsDetailsState.helpers.isReportPreviewEnabled(report);
          outArr.push({
            uuid: id,
            reportId,
            status: isEnabled ? ReportPreviewStatus.enabled : ReportPreviewStatus.disabled,
            includeInBatch: isActive,
          });
        }
        return outArr;
      }, []);

      statusWorkerState.pub.init(statusList);
    }
  }, [listAction, productionList]);

  useEffect(() => {
    if (loading) {
      workerService.pub.cancelPolling();
    }
  }, [loading]);

  useEffect(() => {
    versionsWorkerState.pub.init(productionList);
  }, [datePeriod]);

  const handleExport = (row: NonNullable<IProductionTabDataRes['productionList'][number]>) => {
    const args = {
      productionPrintListId: row.id,
      onDate: datePeriod.fromDate,
    };
    if (row?.reportId === C_Report.R12_LABEL) {
      setIsExportInProcess(true);
      productionReportService.pub.exportLabelAssist(args);
    } else if (row?.reportId === C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE) {
      setIsExportInProcess(true);
      productionReportService.pub.exportSmartScale(args);
    }
  };

  const handleExportResult = (exportType: 'labelAssist' | 'smartScale') => {
    setIsExportInProcess(false);
    enqueueSnackbar(t('common.successfully'), {
      type: 'success',
      title:
        exportType === 'labelAssist'
          ? t('production.report_data_was_exported_to_LabelAssist')
          : t('production.report_data_was_exported_to_SmartScale'),
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  };

  const handleRowClick = ({ row }: ProductionTableRow, e: SyntheticEvent) => {
    const isVersionIconTarget = (e.target as HTMLElement).closest('.version-status-icon');
    const rowStatus = previewListStatus.find((item) => item.uuid === row?.id)?.status;
    if (rowStatus && rowStatus !== ReportPreviewStatus.disabled) {
      printJobsDetailsState.pub.selectProductionRecord(row);
      /*
       * The logic is handled here instead of in a separate onClick for the icon
       * because we need to distinguish between clicks on the icon and other row elements.
       */
      versionsWorkerState.pub.updateVersionWorkerState({
        versionStatuses: versions.versionStatuses.map((status) => {
          const isSelected = Boolean(status.id === row.id && isVersionIconTarget);

          return {
            ...status,
            isSelected,
            statusItem: isSelected
              ? status.statusItem === ReportVersionStatus.enabled
                ? ReportVersionStatus.loading
                : ReportVersionStatus.viewed
              : status.statusItem === ReportVersionStatus.viewed
              ? ReportVersionStatus.enabled
              : status.statusItem,
          };
        }),
      });
    }
  };

  const listRows = useMemo<TypeTable['viewRows']>(
    () => ({
      action: ({ row }) => {
        if (
          !printJobsDetailsState.helpers.isReportForPreview(row?.reportId) ||
          previewListStatus.find((item) => item.uuid === row?.id)?.status ===
            ReportPreviewStatus.disabled
        ) {
          return 'production-row row-disabled';
        }
        return 'row-production';
      },
      styles: [
        {
          className: 'row-disabled',
          bgColorHover: 'inherit',
          rowStyles: {
            cursor: 'default',
          },
        },
        {
          className: 'row-production',
          rowStyles: {
            '.additional-info': {
              visibility: 'hidden',
            },
            '&:hover .additional-info, &.Mui-selected .additional-info': {
              visibility: 'visible',
            },
          },
        },
      ],
    }),
    [productionList, previewListStatus],
  );

  const renderActionCell = ({ row }: GridRenderCellParams) => {
    const isExportAction = !printJobsDetailsState.helpers.isReportForPreview(row?.reportId);
    const isEnabledCommon: boolean =
      (row?.addDaysForPreProduction || 0) > 0 ||
      datePeriod.fromDate !== datePeriod.toDate ||
      countOrderPreProduction === undefined
        ? false
        : row?.addDaysForPreProduction === 0 && countOrders > 0;

    return (
      <SIconWrapper>
        {isExportAction ? (
          <SExportIcon
            disabled={!isEnabledCommon || isExportInProcess}
            onClick={() => handleExport(row)}
          />
        ) : !row?.id || !row?.reportId ? null : (
          <PreviewStatusIcon
            id={row?.id}
            onClick={() =>
              statusWorkerState.pub.itemAction({ uuid: row?.id, reportId: row!.reportId || '' })
            }
            status={
              previewListStatus?.find((item) => item.uuid === row.id)?.status ||
              ReportPreviewStatus.disabled
            }
            errorType={previewListStatus?.find((item) => item.uuid === row.id)?.errorType}
          />
        )}
      </SIconWrapper>
    );
  };

  const renderVersionCell = ({ row }: GridRenderCellParams) => {
    const isVisible = reportIdsForVersioning.includes(row?.reportId);
    return isVisible ? (
      <VersionStatusIcon
        id={row?.id}
        previewStatus={
          previewListStatus?.find((item) => item.uuid === row.id)?.status ||
          ReportPreviewStatus.disabled
        }
        versionStatus={
          versions.versionStatuses.find((status) => status.id === row.id)?.statusItem ||
          ReportVersionStatus.enabled
        }
      />
    ) : null;
  };

  const actionAllHandle = () => {
    switch (previewAllStatus) {
      case ReportPreviewStatus.enabled: {
        statusWorkerState.pub.batchStart();
        break;
      }
      case ReportPreviewStatus.ready:
      case 'readyForPrint': {
        onOpenMerged();
        break;
      }
    }
  };

  return (
    <>
      <Table
        columnVisibilityModel={{
          version: canWorkWithVersionsInProduction,
        }}
        heightOffset={275}
        loading={loading}
        data={(productionList as GridValidRowModel[]) || []}
        arrowNavigation={{
          condition: (row) => {
            const rowStatus = previewListStatus.find((item) => item.uuid === row?.id)?.status;
            return !!rowStatus && rowStatus !== ReportPreviewStatus.disabled;
          },
        }}
        onRowClick={handleRowClick}
        rowSelectionModel={selectedRecord?.id}
        viewRows={listRows}
        defaultSorting={params?.sortModel}
        onSort={(sortModel) => {
          printJobsDetailsState.pub.sort(sortModel);
        }}
        onSearch={(search) => printJobsDetailsState.pub.search(search)}
        childrenButtons={() => (
          <SButtonWrapper>
            {canWorkWithVersionsInProduction && (
              <SIconButton
                disabled={loading}
                title={t('common.version')}
                onClick={() => {
                  versionsWorkerState.pub.updateVersionWorkerState({
                    versionStatuses: versions.versionStatuses.map((status) => ({
                      ...status,
                      isSelected: false,
                      statusItem: ReportVersionStatus.enabled,
                    })),
                  });
                  versionsPopup.stream.emit('open', { date: datePeriod.fromDate });
                }}
              >
                <FactCheck />
              </SIconButton>
            )}
            <SIconButton
              onClick={actionAllHandle}
              disabled={
                previewAllStatus === ReportPreviewStatus.disabled ||
                previewAllStatus === ReportPreviewStatus.loading
              }
              title={t('common.preview')}
            >
              {previewAllStatus === ReportPreviewStatus.loading ? (
                <CircularProgress size={20} />
              ) : previewAllStatus === 'readyForPrint' ||
                previewAllStatus === ReportPreviewStatus.ready ? (
                <SPrintIcon active={previewAllStatus === ReportPreviewStatus.ready} />
              ) : (
                <PreviewIcon
                  state={previewAllStatus === ReportPreviewStatus.disabled ? 'disabled' : undefined}
                />
              )}
            </SIconButton>
          </SButtonWrapper>
        )}
      >
        <Column
          field='isActive'
          headerName={t('common.activated')}
          width={95}
          renderCell={(params) => params.value && <CheckIcon />}
          align='center'
          headerAlign='center'
          sortable={false}
        />
        <Column
          field='name'
          headerName={t('common.description')}
          flex={1}
          renderCell={({ row }) => {
            const { status, generatedAt } =
              previewListStatus.find((item) => item.uuid === row.id) || {};
            return (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <span>{row.name}</span>
                {status !== ReportPreviewStatus.loading && (
                  <Typography className='additional-info' ml='6px' variant='body2' fontSize={12}>
                    {generatedAt ? new Date(generatedAt).toLocaleTimeString() : ''}
                  </Typography>
                )}
              </Stack>
            );
          }}
        />
        <Column
          field='version'
          headerName=''
          width={25}
          sortable={false}
          renderCell={renderVersionCell}
        />
        <Column
          field='action'
          width={25}
          headerName=''
          sortable={false}
          renderCell={renderActionCell}
        />
        <Footer
          numberRows={productionList?.length}
          allNumbersRow={allProductionListLength}
          titleRows={t('production.print_lists', { count: productionList?.length })}
          onRefresh={() => printJobsDetailsState.pub.productionList()}
        />
      </Table>
      <versionsPopup.Component />
    </>
  );
};

export const SButtonWrapper = styled('div')(() => ({
  '& .MuiButtonBase-root': {
    margin: '0 0 0 8px',
  },
}));

export const SColumnContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

export const SIconWrapper = styled('div')(() => ({
  alignSelf: 'end',
  '& svg': {
    width: 20,
    height: 20,
  },
}));
const SIconButton = styled(IconButton)(() => ({
  '& svg': {
    width: 20,
    height: 20,
  },
}));
const SPrintIcon = styled(PrintIcon)<{ active?: boolean }>(({ theme, active }) => ({
  ...(active && {
    color: theme.palette.primary.main,
  }),
}));
const SExportIcon = styled(UploadFileIcon)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  color: theme.palette.grey['600'],
  opacity: !disabled ? 1 : 0.3,
  cursor: 'pointer',
}));

type ProductionTableRow = GridRowParams<ProductionListRes[number]>;

interface IProductionListProps {
  data: IProductionTabDataRes;
  previewListStatus: IStatusWorkerState['list'];
  previewAllStatus: IStatusWorkerState['statusAll'];
  versions: IVersionsWorkerState;
  onOpenMerged: () => void;
  datePeriod: IPrintJobsDetailsState['datePeriod'];
  loading: boolean;
}
