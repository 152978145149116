import { LoaderFunction } from 'react-router-dom';
import { take } from 'rxjs';
import { GridSortModel } from '@mui/x-data-grid-premium';

import { responseHandler } from '../../../../shared/responseHandler/responseHandler';
import {
  defaultProductionFilter as filter,
  IDeliveryNotesTabDataRes,
  initPrintJobsDetailsSortModel,
  initPrintJobsDetailsState,
  IPrintJobsDetailsState,
  IProductionTabDataRes,
  PrintJobsTabs,
} from '../../printJobs/states/printJobsDetails.state';
import { storageHelper } from '../../../../shared/helpers/storage';
import { FullPrintJobsDataRes, printJobsService } from '../../services/printJobs.service';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service.ts';

export const storageFilter: IProductionTabDataRes['filter'] | null = storageHelper.session.getItem(
  'printJobs.productionFilterValue',
);
export const storageProductionSortModel: GridSortModel | null = storageHelper.local.getItem(
  'printJobs.productionSortModel',
);
export const storagePrintDeliveryNotesSortModel: GridSortModel | null = storageHelper.local.getItem(
  'printJobs.printDeliveryNotesSortModel',
);

export const printJobsLoader: LoaderFunction = async ({ params }): Promise<IPrintJobsLoader> => {
  const tab = params.tab as PrintJobsTabs;
  const canWorkWithVersionsInProduction: boolean =
    !!companyConfigService.getCachedConfigs()?.canWorkWithVersionsInProduction;
  let initPrintJobsDetailsData = { ...initPrintJobsDetailsState };
  initPrintJobsDetailsData.action = 'loader';
  initPrintJobsDetailsData = await resolveDataForTabAndIncomingOrders(
    initPrintJobsDetailsData,
    tab,
  );
  return { initPrintJobsDetailsData, canWorkWithVersionsInProduction };
};

async function resolveDataForTabAndIncomingOrders(
  data: IPrintJobsDetailsState,
  selectedTab: PrintJobsTabs,
): Promise<IPrintJobsDetailsState> {
  if (!Object.values(PrintJobsTabs).includes(selectedTab)) {
    location.href = '/404';
  }
  const sortModel =
    (selectedTab === PrintJobsTabs.production
      ? storageProductionSortModel
      : storagePrintDeliveryNotesSortModel) ||
    initPrintJobsDetailsSortModel(selectedTab === PrintJobsTabs.production ? 'name' : 'orderNo');
  const { dataTab, statusList } = await printJobsService.globHelpers.streamToPromise(
    printJobsService.sub.fullPrintJobsDataDependedOnSelectedTab().pipe(
      take(1),
      responseHandler<FullPrintJobsDataRes>({
        errorReturnType: {
          dataTab: {},
          statusList: {},
        },
      }),
    ),
    () =>
      printJobsService.pub.fullPrintJobsDataDependedOnSelectedTab({
        selectedTab,
        datePeriod: data?.datePeriod,
        filter,
      }),
  );
  data.selectedTab = selectedTab;
  data.statusList = statusList;
  data.dataTabs[selectedTab] = {
    ...dataTab,
    params: { sortModel, search: '' },
    ...(selectedTab === PrintJobsTabs.production && { filter }),
  } as IProductionTabDataRes | IDeliveryNotesTabDataRes;
  return data || {};
}

export interface IPrintJobsLoader {
  initPrintJobsDetailsData: IPrintJobsDetailsState;
  canWorkWithVersionsInProduction: boolean;
}
