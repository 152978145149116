import { FC } from 'react';
import { environment } from '../../../../../../../environment';
import useLanguage from '../../../../../../hooks/useLanguage';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { C_Language } from '../../../../../../../graphql/generatedModel';

export const Language: FC = () => {
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
  const { currentLanguage, changeLanguageHandler } = useLanguage();

  return (
    <>
      {appEnv === 'development' && (
        <Select
          data-testid='language'
          value={currentLanguage}
          onChange={(e: SelectChangeEvent) => {
            changeLanguageHandler(e.target.value as C_Language);
          }}
          size='small'
          autoWidth
        >
          <MenuItem value={C_Language.L2_GERMAN}>de</MenuItem>
          <MenuItem value={C_Language.L1_ENGLISH}>en</MenuItem>
        </Select>
      )}
    </>
  );
};
