import { gql } from 'graphql-request';

export const getArticlesList = gql`
  query listWA_Articles($filter: WA_ArticleFilter) {
    wawiAssist {
      listWA_Articles(filter: $filter) {
        id
        description
        articleNo
        isFractionArticle
        isDough
        isFermentationInterrupt
      }
    }
  }
`;
export const getArticleProductionTabData = gql`
  query getArticleProductionTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        daysToProduce
        productionRecipeId
        isDough
        isFermentationInterrupt
        doughWeightBeforeBaking
        doughArticleId
        countPerSheetOfCuttingMachine
        countOfSheetsPerCuttingMachine
        isShowOnProductionPrintLists
        isDoughSplitting
        doughSplittingDough1Amount
        weightOfLiterOfDough
        roundLitersTo
        isFractionArticle
        fractionArticleId
        usedPartOfFractionArticle
        numberOfPartsOfFractionArticle
        isRoundFractionArticleAmount
        articleDoughDataFromRA {
          id
          intermediateWeight
        }
        articleProductionCalculation {
          id
          isFermentationInterrupt
          addedArticleDescription
          addedArticleNo
          addedArticleId
          multiplier
        }
        willReplacedWithProductionCalc
        isProductionQuantityRounding
        productionQuantityRoundTo
        articleProductionCalculationDependencies {
          whichArticlesThisUses
          whichArticlesUseThis
        }
      }
    }
  }
`;

export const getArticleProductionCalculationDependencies = gql`
  query getArticleProductionCalculationDependencies($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        articleProductionCalculationDependencies {
          whichArticlesThisUses
          whichArticlesUseThis
        }
      }
    }
  }
`;

export const getArticleCashAssitTabData = gql`
  query getArticleCashAssitTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        caVisibilityStatusId
        caProductGroupId
        caTemplateId
      }
    }
  }
`;

export const getArticleLabelDataFromRA = gql`
  query getArticleLabelDataFromRA($recipeId: ID!) {
    wawiAssist {
      getWA_ArticleLabelDataFromRA(recipeId: $recipeId) {
        id
        autoDeclaration
        declaration
        isAutoDeclaration
        consumersInformation
        consumersStorageInfo
        daysToSell
        daysToConsume
        daysShelfLife
        isStoreInCoolPlace
        productOutputWeight
        sellingPoints
        sellingPointsFreezer
        sellingWeight
      }
    }
  }
`;

export const getArticlePostTabData = gql`
  query getArticlePostTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        shippingWeight
      }
    }
  }
`;

export const getArticleLabelTabData = gql`
  query getArticleLabelTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        customLabelDescription
        origin
        eanCode
        isEanCodeManagedByCA
        declaration
        isStoreInCoolPlace
        storagePlace
        consumersStorageInfo
        sellingWeight
        consumersInformation
        daysToSell
        daysToConsume
        daysShelfLife
        articleLabelDataFromRA {
          id
          autoDeclaration
          declaration
          isAutoDeclaration
          isStoreInCoolPlace
          daysToSell
          daysToConsume
          productOutputWeight
          consumersInformation
          consumersStorageInfo
          sellingPoints
          sellingWeight
          daysShelfLife
          sellingPointsFreezer
        }
      }
    }
  }
`;

export const getArticleGeneralTabData = gql`
  query getArticleGeneralTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        description
        note
        isNotifyOnOrderStage
        articleNo
        isActive
        discountGroupId
        productionGroupId
        marketingGroupId
        saleUnitId
        quantityPerLot
        vatCode
        isNoDiscount
        shippingPointId
        price1
        price2
        price3
        price4
        price5
        price6
        price7
        price8
        price9
        price10
        articleSpecialPrices {
          id
          fromDate
          toDate
          isActual
          price1
          price2
          price3
          price5
          price4
          price6
          price7
          price8
          price9
          price10
          description
        }
      }
    }
  }
`;

export const getArticleListsTabData = gql`
  query getArticleListsTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        id
        articleListsCheckedState {
          id
          label: name
          checked
        }
      }
    }
  }
`;

export const getArticleAvailabilityTabData = gql`
  query getArticleAvailabilityTabData($id: ID!) {
    wawiAssist {
      getArticleData(id: $id) {
        availabilityInProcurementKindId
        isDeliveredMon
        isDeliveredTue
        isDeliveredWed
        isDeliveredThu
        isDeliveredFri
        isDeliveredSat
        isDeliveredSun
        articleAssortimentGroupsCheckedState {
          id
          label: name
          checked
        }
        articleDeliveryPeriodsSettings {
          articleRegularDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isRepeatYearly
          }
          articleCustomWeekDaysAndDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isDeliveredMon
            isDeliveredTue
            isDeliveredWed
            isDeliveredThu
            isDeliveredFri
            isDeliveredSat
            isDeliveredSun
            isRepeatYearly
          }
          articleVacations {
            id
            fromDate
            toDate
            description
          }
        }
      }
    }
  }
`;

export const createArticle = gql`
  mutation createArticle($data: WA_CreateArticleInput!) {
    wawiAssist {
      createWA_Article(data: $data) {
        id
        description
        articleNo
        isDough
        isFractionArticle
        isFermentationInterrupt
      }
    }
  }
`;

export const copyArticle = gql`
  mutation copyArticle($originalId: ID!, $data: WA_CreateArticleInput!) {
    wawiAssist {
      copyWA_Article(originalId: $originalId, data: $data) {
        id
        description
        articleNo
        isDough
        isFractionArticle
        isFermentationInterrupt
      }
    }
  }
`;
export const saveArticleData = gql`
  mutation SaveArticleData($dataToSave: WA_SaveArticleInput!) {
    wawiAssist {
      saveArticleData(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          id
          description
          articleNo
          isFractionArticle
          isDough
          isFermentationInterrupt
        }
      }
    }
  }
`;

export const validateArticleFieldsOnServer = gql`
  query validateWA_ArticleFields($fieldForCheck: WA_ArticlesFieldForCheck!, $id: ID) {
    wawiAssist {
      validateWA_ArticleFields(fieldsForCheck: $fieldForCheck, id: $id) {
        articleNo
        productionRecipeId
      }
    }
  }
`;

export const getArticleDoughDataFromRA = gql`
  query getWA_ArticleDoughDataFromRA($recipeId: ID!) {
    wawiAssist {
      getWA_ArticleDoughDataFromRA(recipeId: $recipeId) {
        id
        rMsTotalWeight
        nonTSLosses
        roundToInteger
        linkWeightMU
        intermediateWeight
      }
    }
  }
`;
