import { GridSortModel } from '@mui/x-data-grid-premium';
import { BehaviorSubject, map, merge, Observable, share, switchMap, tap } from 'rxjs';

import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { storageHelper } from '../../../shared/helpers/storage';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import {
  DictCustomerGroupsRes,
  DictCustomerListsRes,
  DictTourplansRes,
} from '../../../shared/services/configsData/configsData.service';
import { Pub, State, Sub } from '../../../shared/state/state.abstract';
import { customerService, ListCustomersRes } from '../services/customer.service';

export const initState: ICustomersListState = {
  action: undefined,
  list: [],
  fullListCount: 0,
  search: '',
  filter: { isActive: true },
  filterData: { customerLists: [], customerGroups: [], tourPlans: [] },
  selectedRecord: undefined,
  sortModel: [{ field: 'customerNo', sort: 'asc' }],
  customerTitle: '',
  clearCacheFlag: false,
  params: {},
};

class PubImpl extends Pub<ICustomersListState> {
  init(params: Partial<ICustomersListState>) {
    this.emit('init', params);
  }
  queryList() {
    this.emit('queryList', { params: { cache: false } });
  }
  filter(filter: ICustomersListFilter) {
    const { customerGroupId, customerListId, tourplanId } = filter;
    const convertedFilter = {
      ...filter,
      ...(customerGroupId && { customerGroupId: Number(customerGroupId) }),
      ...(customerListId && { customerListId: Number(customerListId) }),
      ...(tourplanId && { tourplanId: Number(tourplanId) }),
    };
    this.emit('filter', { filter: convertedFilter });
  }
  selectRecord(selectedRecord: ICustomersListState['selectedRecord']) {
    this.emit('selectRecord', { selectedRecord });
    storageHelper.session.setItem('customer.selectedRecord', selectedRecord);
  }
  addRecordToList(record: NonNullable<ICustomersListState['selectedRecord']>) {
    const streamV = this.stream$.getValue();
    this.stream$.next({ ...streamV, list: [...streamV.list, record] });
    this.selectRecord(record);
  }
  unselectRecord() {
    this.emit('unselectRecord', { selectedRecord: undefined });
  }
  updateCustomer(customer: ListCustomersRes[number]) {
    this.emit('updateCustomer', { params: customer });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: GridSortModel) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('customer.customersListSort', sortModel);
  }
  clearCacheFlag(clearCacheFlag: boolean) {
    this.emit('updateCustomer', { clearCacheFlag });
  }
}

class SubImpl extends Sub<ICustomersListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  shareDataForTabLoader(): ICustomersListState {
    return this.stream$.getValue();
  }

  protected actionHandlers(): Observable<ICustomersListState> {
    return merge(this.queryList(), this.updateState(), this.updateCustomer()).pipe(
      map(({ action, list, filterData, ...rest }) => {
        let sortedList = [...(list || [])];
        let selectedRecord = rest.selectedRecord;
        const fullListCount = list?.length || 0;
        const resolvedList = dataHelper
          .data(list as [])
          .sort({ sortModel: rest.sortModel, callback: (sorted) => (sortedList = sorted as []) })
          .search({ search: rest.search, fields: ['customerNo', 'internalOrFullName'] })
          .result() as ListCustomersRes;
        // Check if selectedRecord is in new list. Otherwise select the first one from the sorted list.
        if (action === 'queryList' || action === 'filter') {
          const isSelectedRecordInList = !!sortedList.find(
            (item) => item?.id === rest.selectedRecord?.id,
          );
          if (!isSelectedRecordInList) {
            selectedRecord = sortedList[0] || null;
            customersListState.pub.selectRecord(selectedRecord);
            storageHelper.session.setItem('customer.selectedRecord', selectedRecord);
          }
        }
        const newState = {
          ...rest,
          action,
          list: resolvedList,
          fullListCount,
          selectedRecord,
          filterData,
        };
        // Update stream value
        this.stream$.next({ ...newState, list: sortedList, action: 'internalUpdate' });
        return newState;
      }),
      tap(() => this.loading$.next(false)),
      this.customerTitlePipe,
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  private queryList(): Observable<ICustomersListState> {
    return this.actionListener(['queryList', 'filter']).pipe(
      tap(() => this.loading$.next(true)),
      tap(({ filter, params: { cache } }) => {
        customerService.pub.listGridCustomers({ filter, cache });
      }),
      switchMap((state) => {
        return customerService.sub.listGridCustomers().pipe(
          responseHandler<ListCustomersRes>({ errorReturnType: [] }),
          map((v) => ({ ...state, list: v })),
        );
      }),
    );
  }
  private updateState(): Observable<ICustomersListState> {
    return this.actionListener(['init', 'search', 'selectRecord', 'unselectRecord', 'sort']);
  }
  private updateCustomer(): Observable<ICustomersListState> {
    return this.actionListener('updateCustomer').pipe(
      map((state) => {
        const { params } = state;
        if (params) {
          const itemIdx = state.list.findIndex((item) => item.id === params.id);
          if (itemIdx !== -1) {
            const temp = [...state.list];
            temp.splice(itemIdx, 1, params);
            storageHelper.memory.setItem('customer.list', temp);
            state.list = temp;
          }
        }

        return state;
      }),
    );
  }

  private customerTitlePipe(src: Observable<ICustomersListState>) {
    return src.pipe(
      map<ICustomersListState, ICustomersListState>((state) => {
        const { action, params, selectedRecord } = state;
        const {
          customerNo: paramCustomerNo,
          internalOrFullName: paramInternalOrFullName,
          id: paramId,
        } = params || {};
        const {
          customerNo: selectedCustomerNo,
          internalOrFullName: selectedInternalOrFullName,
          id: selectedId,
        } = selectedRecord || {};

        const customerNo =
          action === 'updateCustomer' &&
          paramCustomerNo !== selectedCustomerNo &&
          paramId === selectedId
            ? paramCustomerNo
            : selectedCustomerNo;

        const internalOrFullName =
          action === 'updateCustomer' &&
          paramInternalOrFullName !== selectedInternalOrFullName &&
          paramId === selectedId
            ? paramInternalOrFullName
            : selectedInternalOrFullName;

        state.customerTitle =
          customerNo && internalOrFullName
            ? `${customerNo} ${internalOrFullName}`
            : customerNo || internalOrFullName || '';
        return state;
      }),
    );
  }
}

class CustomersList extends State<ICustomersListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const customersListState = new CustomersList(initState);

export const resetCustomerDeps = () => {
  storageHelper.memory.removeItem('configsData.dictCustomers');
  customersListState.pub.clearCacheFlag(true);
};

interface ICustomersListFilter {
  isActive?: boolean;
  customerGroupId?: string;
  customerListId?: string;
  tourplanId?: string;
}
interface ICustomersListFilterData {
  customerGroups: DictCustomerGroupsRes;
  customerLists: DictCustomerListsRes;
  tourPlans: DictTourplansRes;
}

export interface ICustomersListState {
  action:
    | 'init'
    | 'queryList'
    | 'filter'
    | 'selectRecord'
    | 'updateCustomer'
    | 'unselectRecord'
    | 'search'
    | 'sort'
    | 'internalUpdate'
    | 'loader'
    | undefined;
  params: any;
  list: ListCustomersRes;
  fullListCount: number;
  filter: ICustomersListFilter;
  filterData: ICustomersListFilterData;
  selectedRecord: ListCustomersRes[number] | undefined;
  search: string;
  customerTitle: string;
  sortModel: GridSortModel;
  clearCacheFlag: boolean;
}
