import { GridSortModel } from '@mui/x-data-grid-premium';
import {
  BehaviorSubject,
  delay,
  filter,
  map,
  merge,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';

import i18next from 'i18next';
import {
  C_Created_Orders_Filter_Mode,
  Scalars,
  Wa_CreatedOrdersGridFilter,
} from '../../../../graphql/generatedModel';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service.ts';
import { dataHelper } from '../../../../shared/helpers/data/data.helper.ts';
import { storageHelper } from '../../../../shared/helpers/storage';
import { Pub, State, Sub } from '../../../../shared/state/state.abstract.ts';
import {
  CreatedOrdersListRes,
  DeleteOrderRes,
  editOrderService,
} from '../../services/editOrder.service.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';

export const initOrderListState: IOrderListState = {
  action: undefined,
  filter: {
    filterBy: C_Created_Orders_Filter_Mode.DATE,
    date: undefined, // 2023-10-27
    dateTo: undefined,
    emailState: null,
    withDeleted: false,
    customerId: undefined,
    customerGroupId: undefined,
    customerListId: undefined,
    deliveryNotesRange: undefined,
  },
  search: '',
  sortModel: [{ field: 'orderNo', sort: 'asc' }],
  selectedOrder: undefined,
  orderList: [],
  allOrderListLength: 0,
};
export const commonFields: (keyof Wa_CreatedOrdersGridFilter)[] = [
  'filterBy',
  'emailState',
  'withDeleted',
];
export const fieldsByFolderName: TFilterConnections = {
  [C_Created_Orders_Filter_Mode.DATE]: {
    required: ['date'],
    connected: ['date'],
  },
  [C_Created_Orders_Filter_Mode.DATE_RANGE]: {
    required: ['date', 'dateTo'],
    connected: ['date', 'dateTo'],
  },
  [C_Created_Orders_Filter_Mode.DELIVERY_NOTES_RANGE]: {
    required: ['deliveryNotesRange'],
    connected: ['deliveryNotesRange'],
  },
  [C_Created_Orders_Filter_Mode.CUSTOMER]: {
    required: ['customerId'],
    connected: ['customerId'],
  },
  [C_Created_Orders_Filter_Mode.CUSTOMER_GROUP]: {
    required: ['customerGroupId'],
    connected: ['customerGroupId', 'date', 'dateTo'],
  },
  [C_Created_Orders_Filter_Mode.CUSTOMER_LIST]: {
    required: ['customerListId'],
    connected: ['customerListId', 'date', 'dateTo'],
  },
  [C_Created_Orders_Filter_Mode.ONE_TIME_CUSTOMERS]: {
    required: ['date', 'dateTo'],
    connected: ['date', 'dateTo'],
  },
};

class PubImpl extends Pub<IOrderListState> {
  init(params: Partial<IOrderListState>) {
    this.emit('init', params);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: IOrderListState['sortModel']) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('editOrder.orderList.sortModel', sortModel);
  }
  selectedOrder(selectedOrder: IOrderListState['selectedOrder']) {
    this.emit('selectedOrder', { selectedOrder });
    storageHelper.session.setItem('editOrder.selectedOrder', selectedOrder);
  }
  orderList() {
    this.emit('orderList', {});
  }
  deleteSelectedOrder() {
    this.emit('deleteSelectedOrder', {});
  }
  deleteSelectedOrderFromList() {
    this.emit('deleteSelectedOrderFromList', {});
  }
  editSelectedOrder(newOptions: TEditSelectedOrder) {
    if (newOptions) {
      this.emit('editSelectedOrder', { params: newOptions });
    }
  }
  filter(filter: IOrderListState['filter'], shouldPerformRequest = true) {
    orderListState.sub.shouldPerformRequest$.next(shouldPerformRequest);
    this.emit('filter', { filter });
  }
}

class SubImpl extends Sub<IOrderListState> {
  public shouldPerformRequest$ = new BehaviorSubject<boolean>(false);
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IOrderListState> {
    return merge(
      this.initState(),
      this.updateSortingSearchAndSelectedOrder(),
      this.listenForStateUpdates(),
      this.deleteSelectedOrder(),
      this.editSelectedOrder(),
      this.deleteSelectedOrderFromList(),
    ).pipe(
      map(({ orderList, search, sortModel, allOrderListLength, ...rest }) => {
        let sortedOrderList = [...orderList];
        let selectedOrder = rest.selectedOrder;
        allOrderListLength = orderList.length;
        orderList = dataHelper
          .data(orderList as [])
          .sort({
            sortModel,
            callback: (data) => {
              sortedOrderList = data as [];
            },
          })
          .search({ search, fields: ['orderNo', 'internalOrFullName'] })
          .result() as IOrderListState['orderList'];

        if (rest.action === 'orderList' || rest.action === 'filter') {
          const foundSelectedRecord =
            orderList.find((item) => item?.orderId === selectedOrder?.orderId) || orderList[0];
          selectedOrder = foundSelectedRecord || null;
          storageHelper.session.setItem('editOrder.selectedOrder', selectedOrder);
        }
        const updatedState = {
          ...rest,
          orderList,
          selectedOrder,
          search,
          sortModel,
          allOrderListLength,
        };
        this.stream$.next({
          ...updatedState,
          orderList: sortedOrderList,
          action: 'internalUpdate',
        });
        return updatedState;
      }),
      tap(({ action }) => {
        if (action !== 'sort' && action !== 'search') this.loading$.next(false);
      }),
      filter((state) => typeof state.selectedOrder === 'object'),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  sharedValues(): Observable<ISharedValues> {
    // if you need current values of this state in other places
    const values = this.stream$.getValue();
    return of({
      date: values?.selectedOrder?.orderDate,
      order: values?.selectedOrder,
      lastOrderInTable: values?.orderList?.length === 1,
    });
  }

  /* PRIVATE METHODS */
  private listenForStateUpdates(): Observable<IOrderListState> {
    return this.actionListener(['orderList', 'filter']).pipe(
      switchMap((state) => {
        const shouldPerformRequest = !(
          state.action === 'filter' && !this.shouldPerformRequest$.getValue()
        );
        if (shouldPerformRequest) {
          this.loading$.next(true);
          editOrderService.pub.createdOrdersList({ filter: state.filter });
          this.shouldPerformRequest$.next(false);
          return editOrderService.sub.createdOrdersList().pipe(
            responseHandler<CreatedOrdersListRes>({ errorReturnType: [] }),
            map((v) => ({ ...state, orderList: v })),
          );
        } else {
          this.shouldPerformRequest$.next(false);
          return of(state);
        }
      }),
    );
  }
  private deleteSelectedOrder(): Observable<IOrderListState> {
    return this.actionListener('deleteSelectedOrder').pipe(
      tap(({ selectedOrder }) => {
        editOrderService.pub.deleteOrder({ id: selectedOrder!.orderId });
        snackbarService.pub.show({
          content: i18next.t('order.deleting_order'),
          type: 'loading',
          id: 'deleting_order',
        });
      }),
      switchMap(() => {
        return editOrderService.sub
          .deleteOrder()
          .pipe(responseHandler<DeleteOrderRes>({ errorReturnType: undefined }), take(1));
      }),
      tap((response) => {
        snackbarService.pub.hide('deleting_order');
        if (response)
          snackbarService.pub.show({
            content: i18next.t('order.order_successfully_deleted'),
            type: 'success',
          });
      }),
      filter((response) => !!response),
      map(() => {
        const state = structuredClone(this.stream$.getValue());
        const stateWithoutOldOrder = this.deleteSelected(state);
        return stateWithoutOldOrder;
      }),
    );
  }
  private deleteSelectedOrderFromList(): Observable<IOrderListState> {
    return this.actionListener('deleteSelectedOrderFromList').pipe(
      map((state) => {
        const stateWithoutOldOrder = this.deleteSelected(state);
        return stateWithoutOldOrder;
      }),
    );
  }
  private editSelectedOrder(): Observable<IOrderListState> {
    return this.actionListener('editSelectedOrder').pipe(
      map((state) => {
        const newOptions = state.params as TEditSelectedOrder;
        const newSelected = {
          ...state?.selectedOrder,
          ...newOptions,
        } as CreatedOrdersListRes[number];
        const newOrderList = state?.orderList?.map((order) => {
          if (order?.orderId === newOptions?.orderId) {
            return newSelected;
          }
          return order;
        });
        storageHelper.session.setItem('editOrder.selectedOrder', newSelected);
        return {
          ...state,
          selectedOrder: newSelected,
          orderList: newOrderList,
        };
      }),
    );
  }
  private updateSortingSearchAndSelectedOrder(): Observable<IOrderListState> {
    return this.actionListener(['search', 'sort', 'selectedOrder']);
  }
  private initState(): Observable<IOrderListState> {
    // emits the first value for correct operation of the pairwise method
    return this.actionListener('init').pipe(delay(0));
  }

  /* UTILITY start */

  private deleteSelected(state: IOrderListState): IOrderListState {
    const orderListCopy = [...state.orderList];
    const index = orderListCopy.indexOf(state.selectedOrder!);
    const newOrder =
      (index === state.orderList.length - 1
        ? (state.selectedOrder = state.orderList[state.orderList.length - 2])
        : (state.selectedOrder = state.orderList[index + 1])) || null;
    orderListCopy.splice(index, 1);
    state.orderList = orderListCopy;
    state.selectedOrder = newOrder;
    storageHelper.session.setItem('editOrder.selectedOrder', newOrder);
    return state;
  }

  /* UTILITY end */
}

class OrderListState extends State<IOrderListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const orderListState = new OrderListState(initOrderListState);

type TFilterConnections = {
  [key in C_Created_Orders_Filter_Mode]: {
    required: (keyof Wa_CreatedOrdersGridFilter)[];
    connected: (keyof Wa_CreatedOrdersGridFilter)[];
  };
};

type TEditSelectedOrder = Partial<IOrderListState['selectedOrder']>;
export interface IOrderListState {
  action:
    | 'init'
    | 'filter'
    | 'search'
    | 'sort'
    | 'loader'
    | 'selectedOrder'
    | 'orderList'
    | 'deleteSelectedOrder'
    | 'deleteSelectedOrderFromList'
    | 'internalUpdate'
    | 'editSelectedOrder'
    | undefined;
  filter: Wa_CreatedOrdersGridFilter;
  search: string;
  sortModel: GridSortModel;
  selectedOrder: CreatedOrdersListRes[number] | undefined;
  orderList: CreatedOrdersListRes;
  allOrderListLength: number;
  params?: any;
}

export interface ISharedValues {
  date: Scalars['Date'];
  order: IOrderListState['selectedOrder'];
  lastOrderInTable: boolean;
}
