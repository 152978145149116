import { LocalizationHelper } from '../helpers/localization/localization.helper.ts';
import {
  C_Attached_Orders_Action,
  C_Procurement_Product_Kind,
  C_Besr_Format,
  C_Booking_Type,
  C_Ca_Visibility_Status,
  C_Country,
  C_Customer_Address_Kind,
  C_Customer_Def_Order_Source,
  C_Debitor_Status,
  C_Direct_Debit_Banks,
  C_Discount_Kind,
  C_Discounts_And_Prices_Kind,
  C_Freezer_Condition,
  C_History_Operations,
  C_Invoice_Deletion_Possibility,
  C_Invoice_Interval,
  C_Kg_Representation,
  C_Order_Positions_Sorting_Kind,
  C_Order_Type,
  C_Production_Article_Sorting_Kind,
  C_Production_Customer_Sorting_Kind,
  C_Report,
  C_Report_Group,
  C_Rounding_Method,
  C_Sale_Unit,
  C_Special_Price_Type,
  C_Tax_System,
  C_Vat_Code,
  Dict_Linked_Records_Fields,
  Dict_Linked_Records_Names,
  C_Email_Tracking_Event_Type,
} from '../../graphql/generatedModel.ts';

type Keys =
  | C_Sale_Unit
  | C_Vat_Code
  | C_Order_Type
  | C_Procurement_Product_Kind
  | C_Customer_Address_Kind
  | C_Invoice_Interval
  | C_Report
  | C_Report_Group
  | C_Production_Article_Sorting_Kind
  | C_Production_Customer_Sorting_Kind
  | C_Attached_Orders_Action
  | C_Ca_Visibility_Status
  | C_Country
  | C_Rounding_Method
  | C_Tax_System
  | C_Discount_Kind
  | C_Freezer_Condition
  | C_History_Operations
  | C_Order_Positions_Sorting_Kind
  | C_Besr_Format
  | C_Kg_Representation
  | C_Discounts_And_Prices_Kind
  | C_Customer_Def_Order_Source
  | C_Debitor_Status
  | C_Direct_Debit_Banks
  | C_Special_Price_Type
  | C_Booking_Type
  | Dict_Linked_Records_Names
  | Dict_Linked_Records_Fields
  | C_Invoice_Deletion_Possibility
  | C_Email_Tracking_Event_Type;

const t = LocalizationHelper<Keys>('enums');

export const enumsDe = t({
  [C_Country.CO1_SWITZERLAND]: 'Schweiz',
  [C_Country.CO2_GERMANY]: 'Deutschland',
  [C_Country.CO3_AUSTRIA]: 'Osterreich',
  [C_Country.CO4_MALDIVES]: 'Malediven',
  [C_Country.CO5_LUXEMBOURG]: 'Luxemburg',

  [C_Discount_Kind.DK1_NO_DISCOUNT]: 'Kein Rabatt',
  [C_Discount_Kind.DK2_ALL_ARTICLES]: 'Rabatt auf alle Artikel',
  [C_Discount_Kind.DK3_ARTICLE_GROUPS]: 'Rabatt auf Warengruppen',
  [C_Discount_Kind.DK4_PRICE_REDUCE]:
    'Rabatt auf Bestellung (Artikelpreis wird um den Rabatt reduziert)',
  [C_Discount_Kind.DK5_QUANTITY_DISCOUNT]: 'Mengenrabatt auf Rechnungsbetrag (alle Artikel)',
  [C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS]: 'Mengenrabatt auf Rechnungsbetrag (Warengruppen)',

  [C_Sale_Unit.SU1_PIECE]: 'Stück',
  [C_Sale_Unit.SU2_100G]: '100g',
  [C_Sale_Unit.SU3_1000G]: '1000g',
  [C_Sale_Unit.SU4_PIECE_AND_WEIGTH]: 'Stück und Gewicht',
  [C_Sale_Unit.SU5_OPEN_AMOUNT]: 'Offener Betrag',
  [C_Sale_Unit.SU6_OPEN_AMOUNT_MINUS]: 'Offener Betrag (Minus)',

  [C_Vat_Code.VT1_NORMAL]: 'MwSt Normalsatz',
  [C_Vat_Code.VT2_REDUCED]: 'MwSt reduzierter Satz',
  [C_Vat_Code.VT3_SPECIAL1]: 'MwSt spezial 1',
  [C_Vat_Code.VT4_SPECIAL2]: 'MwSt spezial 2',
  [C_Vat_Code.VT5_ZERO]: 'MwSt 0%',

  [C_Freezer_Condition.FC1_MINUS_18]: 'bei max. -18° C aufbewahren',
  [C_Freezer_Condition.FC2_PLUS_5]: 'bei max. +5° C aufbewahren',

  [C_Order_Type.OT1_ORDER]: 'Bestellung',
  [C_Order_Type.OT2_ADDITIONAL_ORDER]: 'Nachlieferung',
  [C_Order_Type.OT3_PICKING_LIST]: 'Ruestliste',
  [C_Order_Type.OT4_RETURN]: 'Retoure',
  [C_Order_Type.OT5_CREDIT_ORDER]: 'Gutschrift',
  [C_Order_Type.OT6_SPECIAL_ORDER]: 'Sonderbestellung',
  [C_Order_Type.OT7_INTERNET_ORDER]: 'Internet Bestellung',
  [C_Order_Type.OT8_HOGASHOP]: 'Hogashop Bestellung',
  [C_Order_Type.OT9_CATERING]: 'Catering',
  [C_Order_Type.OT11_CASHASSIST_ORDER]: 'CashAssist Bestellung',
  [C_Order_Type.OT12_CASHASSIST_REDISCOUNT]: 'CashAssist Inventur',
  [C_Order_Type.OT13_CASHASSIST_OUTPUT]: 'CashAssist Ausgang',
  [C_Order_Type.OT14_CASHASSIST_RETURN]: 'CashAssist Retoure',
  [C_Order_Type.OT15_CASHASSIST_TRANSFER]: 'CashAssist Transfer',
  [C_Order_Type.OT16_CASHASSIST_ADDITIONAL_ORDER]: 'CashAssist Nachlieferung',
  [C_Order_Type.OT20_CASHASSIST_CUSTOMER_ORDER]: 'CashAssist Kunden Bestellung',
  [C_Order_Type.OT21_CASHASSIST_INVOICE]: 'CashAssist Rechnung',
  [C_Order_Type.OT22_CASHASSIST_CREDIT_CUSTOMER]: 'CashAssist Kunden Kredit',

  [C_Invoice_Interval.II1_MONTHLY]: 'Monatliche Rechnung',
  [C_Invoice_Interval.II2_MONTHLY2]: 'Monatliche Rechnung 2',
  [C_Invoice_Interval.II3_MONTHLY3]: 'Monatliche Rechnung 3',
  [C_Invoice_Interval.II4_MONTHLY4]: 'Monatliche Rechnung 4',
  [C_Invoice_Interval.II5_HALF_MONTHLY]: 'Halbmonatliche Rechnung',
  [C_Invoice_Interval.II6_HALF_WEEKLY]: 'Wöchentliche Rechnung',
  [C_Invoice_Interval.II7_MANUAL]: 'Manuell',
  [C_Invoice_Interval.II8_DIRECT_CASH]: 'Direkte Rechnungsstellung Bar',
  [C_Invoice_Interval.II9_DIRECT_DEBITOR]: 'Direkte Rechnungsstellung Debitor',
  [C_Invoice_Interval.II10_NO_INVOICE_INTERNAL]: '(Keine Rechnung) für interne Kunden',

  [C_Customer_Address_Kind.CAK1_COMPANY]: 'Firma',
  [C_Customer_Address_Kind.CAK2_COMPANY_WITH_DELIVERY_ADDRESS]: 'Firma mit Lieferscheinadresse',
  [C_Customer_Address_Kind.CAK3_PRIVATE_PERSON]: 'Privat',

  [C_Besr_Format.BF1_HSSOFT]: 'HS-Soft',
  [C_Besr_Format.BF2_ABACUS]: 'Abacus',
  [C_Besr_Format.BF3_ABACUS2]: 'Abacus - 2',

  [C_Procurement_Product_Kind.PPK0_NOT_AVAILABLE]: 'Gruppe nicht verfügbar',
  [C_Procurement_Product_Kind.PPK1_MERCHANDISE_ARTICLE]: 'Handelsware',
  [C_Procurement_Product_Kind.PPK2_OWN_PRODUCED_ARTICLE]: 'Eigenproduktion',
  [C_Procurement_Product_Kind.PPK3_RAW_MATERIAL]: 'Rohmaterial für Filiale',

  [C_Report_Group.RG1_PRODUCTION_PRODUCTION]: 'Produktion',
  [C_Report_Group.RG2_CUSTOMER]: 'Kunde',
  [C_Report_Group.RG3_ARTICLE]: 'Artikel',
  [C_Report_Group.RG4_DEFAULT_ORDER]: 'Vorgabebestellung',
  [C_Report_Group.RG5_ORDER]: 'Bestellung',
  [C_Report_Group.RG6_INVOICE]: 'Rechnung',
  [C_Report_Group.RG7_DEBITOR]: 'Debitor',
  [C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS]: 'Versand Spediliste',
  [C_Report_Group.RG9_PRODUCTION_CROSSTABLE]: 'Versand Cross Bericht',
  [C_Report_Group.RG10_OFFER]: 'Angebot',
  [C_Report_Group.RG11_PRODUCTION_SMARTSCALE]: 'Teiglisten (smartScale)',
  [C_Report_Group.RG12_PROCUREMENT]: 'Beschaffung',
  [C_Report_Group.RG13_PROCUREMENT_GOODS]: 'Beschaffungsgüter',
  [C_Report_Group.RG14_PROCUREMENT_SUPPLIERS]: 'Beschaffungslieferanten',

  [C_Report.R0_USER_REPORT]: '',
  [C_Report.R1_BAKING_LIST_2_COLUMNS]: 'Backzettel (2-spaltig)',
  [C_Report.R3_ADDRESS_LIST_A4_LANDSCAPE]: 'Adressliste A4-Quer',
  [C_Report.R4_FACTSHEET]: 'Stammblatt',
  [C_Report.R5_PHONES_LIST]: 'Telefonliste A4-Quer',
  [C_Report.R6_ARTICLE_LIST]: 'Artikelliste',
  [C_Report.R7_WEEKLY_ORDERING_LIST]: 'Wochenbestellblatt',
  [C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE]: 'Lieferschein A4 mit Preis',
  [C_Report.R9_INVOICE_A4]: 'Rechnung A4',
  [C_Report.R10_CUSTOMERS_DEBT_NOTIFIER]: 'Mahnung',
  [C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE]: 'Cross Bericht A4-Quer',
  [C_Report.R12_LABEL]: 'Etikett (LabelAssist)',
  [C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS]: 'Backzettel mit Rezept (2-spaltig)',
  [C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES]: 'Speditionliste (Kunde/Artikel)',
  [C_Report.R16_DELIVERY_NOTE_A4_WITHOUT_PRICE]: 'Lieferschein A4 ohne Preis',
  [C_Report.R17_INVOICE_SUMMARY]: 'Zusammenzug zu Rechnung',
  [C_Report.R18_INVOICE_PAYMENTSLIP]: 'Einzahlungsschein',
  [C_Report.R19_INVOICE_A4_DETAILED]: 'Rechnung A4 detailliert',
  [C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS]: 'Speditionliste (Artikel/Kunden)',
  [C_Report.R21_INVOICE_JOURNAL]: 'Rechnungsjournal A4-Quer',
  [C_Report.R22_BESR_IMPORT_JOURNAL_A4_LANDSCAPE]: 'ISO (CAMT.054) Import Journal',
  [C_Report.R23_DEBITOR_JOURNAL_A4_LANDSCAPE]: 'Debitor Journal',
  [C_Report.R24_PAYMENT_JOURNAL_A4_LANDSCAPE]: 'Zahlung Journal',
  [C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED]: 'Backzettel mit Rezept & Produkten (2-spaltig)',
  [C_Report.R26_BAKING_LIST_WITH_RECIPE_A4]: 'Backzettel mit Rezept (A4)',
  [C_Report.R27_BAKING_LIST_WITH_RECIPE_A5]: 'Backzettel mit Rezept (A5)',
  [C_Report.R28_WEEKLY_ORDERING_LIST_BLANK_QUANTITY]: 'Wochenbestellblatt (Leer Menge)',
  [C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE]: 'Lieferschein A5-Quer mit Preis',
  [C_Report.R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE]: 'Lieferschein A5-Quer ohne Preis',
  [C_Report.R31_INVOICE_A4_ARTICLE_GROUPING]: 'Rechnung A4 (Artikelgruppierung)',
  [C_Report.R32_TOURLIST]: 'Tourenliste',
  [C_Report.R33_PRICE_LIST_FOR_CUSTOMER]: 'Preisliste für Kunden',
  [C_Report.R34_BAKING_LIST_A4]: 'Backzettel (1-spaltig)',
  [C_Report.R35_EXTENDED_PAYMENT_JOURNAL]: 'Zahlungsjournal detailiert',
  [C_Report.R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES]: 'Artikelliste (alle Preise)',
  [C_Report.R37_OFFER_A4]: 'Angebot A4',
  [C_Report.R38_OFFER_A4_ARTICLE_GROUPING]: 'Angebot A4 (Artikelgruppierung)',
  [C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING]: 'Sammelrechnung A4 (Kundengruppierung)',
  [C_Report.R40_DELIVERY_NOTE_A4_OVERVIEW]: 'Lieferung A4 Übersicht',
  [C_Report.R41_BAKING_LIST_A4_DETAILED]: 'Backzettel detailliert (1-spaltig)',
  [C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE]:
    'Speditionliste (Kunde/Artikel) separate Seiten',
  [C_Report.R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT]: 'Lieferschein A4 mit Preis und Rabatte',
  [C_Report.R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT]:
    'Lieferschein A5-Quer mit Preis und Rabatt',
  [C_Report.R45_SHIPPING_LABEL_ADDRESS_ONLY]: 'Das Versandetikett Adresse nur',
  [C_Report.R46_SHIPPING_LABEL_FOR_ORDERED_ARTICLE]: 'Versandetikett für bestellte Artikel',
  [C_Report.R47_WEEKLY_ORDERINGLIST_BLANK_QUANTITY_BLANK_DATE]:
    'Wochenbestellblatt (leere Menge, kein Datum)',
  [C_Report.R48_OFFER_A4_WITH_IMAGES]: 'Angebot A4 mit Bilder',
  [C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE]: 'Baking list 2 columns (smartScale)',
  [C_Report.R50_ARTICLE_LIST_WITH_IMAGES]: 'Artikelliste mit Bilder',
  [C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION]: 'Lieferscheine',
  [C_Report.R52_DELIVERY_OVERVIEW]: 'Lieferübersicht',
  [C_Report.R53_PROCUREMENT_ORDER_A4]: 'Beschaffungsauftrag A4',
  [C_Report.R54_PROCUREMENT_PROJECT_A4]: 'Beschaffungsproject A4',
  [C_Report.R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN]: 'Lieferschein A4 mit Preis und Herkunft',
  [C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE]: 'Backzettel mit Differenz',
  [C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS]: 'Backzettel Rohmaterial->Rezept (2-spaltig)',
  [C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE]:
    'Backzettel Differenz mit Rezept & Produkten',
  [C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR]: 'Einzahlungsschein mit QR',
  [C_Report.R60_STOCK_LIST]: 'Inventurliste',
  [C_Report.R61_STOCK_VALUE]: 'Lagerwerte',
  [C_Report.R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE]:
    'Lieferschein (Anfahrt) A4 mit Preis',
  [C_Report.R63_PROCUREMENT_SUPPLIER_GOODS_A4]: 'Bestellliste A4',
  [C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP]:
    'Mahnung mit Rechnung und Einzahlungsschein',
  [C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT]: 'Cross Bericht A4-Hoch',
  [C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES]: 'Backliste für Kuchen & Torten',
  [C_Report.R67_CUSTOMER_PAYMENT_INFO_OVERVIEW]: 'Übersicht zahlung',
  [C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE]:
    'Speditionsliste mit Differenz (Artikel/Kunden)',
  [C_Report.R_NOT_TO_PRINT]: '<nicht drucken>',

  [C_Production_Article_Sorting_Kind.PASK1_DOUGH_ARTICLE_NO]: 'Teigzugehörigkeit/Artikel-Nr.',
  [C_Production_Article_Sorting_Kind.PASK2_ARTICLE_NO]: 'Artikel-Nr.',
  [C_Production_Article_Sorting_Kind.PASK3_DESCRIPTION]: 'Bezeichnung',
  [C_Production_Article_Sorting_Kind.PASK4_GROUP_ARTICLE_NO]: 'Gruppe/Artikel-Nr.',
  [C_Production_Article_Sorting_Kind.PASK5_GROUP_DESCRIPTION]: 'Gruppe/Bezeichnung',
  [C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO]:
    'Kunden-Nr./Lieferschein-Nr./Artikel-Nr.',
  [C_Production_Article_Sorting_Kind.PASK7_ARTICLE_NO_SETUP_STATION]: 'Artikel-Nr./Rüstplatz',
  [C_Production_Article_Sorting_Kind.PASK8_DELIVERY_TIME]: 'Lieferzeit',

  [C_Production_Customer_Sorting_Kind.PCSK1_CUSTOMER_NO]: 'Kunden-Nr.',
  [C_Production_Customer_Sorting_Kind.PCSK2_CUSTOMER_NAME]: 'Kundenname',

  [C_Attached_Orders_Action.AOA0_UNBIND]: 'Lieferscheine zurücksetzen',
  [C_Attached_Orders_Action.AOA1_DELETE]: 'Löschen fakturierte Lieferscheine',

  [C_Ca_Visibility_Status.CAVS1_ALWAYS]: 'Immer',
  [C_Ca_Visibility_Status.CAVS2_ONLY_CR_MODE]: 'Nur Kassenmodus',
  [C_Ca_Visibility_Status.CAVS3_ONLY_ORDER_MODE]: 'Nur Bestellmodus',
  [C_Ca_Visibility_Status.CAVS4_DISABLED]: 'Deaktiviert',

  [C_Rounding_Method.RM1_NOT_USED]: 'Nicht verwendet',
  [C_Rounding_Method.RM2_TO_0_05]: 'Gerundet auf .05',
  [C_Rounding_Method.RM3_TO_0_01]: 'Gerundet auf .01',
  [C_Rounding_Method.RM4_TO_0_001]: 'Gerundet auf .001',
  [C_Rounding_Method.RM5_TO_0_1]: 'Gerundet auf .1',

  [C_Order_Positions_Sorting_Kind.OPS1_ARTICLE_NO]: 'Artikel-Nr.',
  [C_Order_Positions_Sorting_Kind.OPS2_DESCRIPTION]: 'Bezeichnung',
  [C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL]: 'Original',

  [C_Kg_Representation.KGR1_1_IS_1]: 'Einheit = 1',
  [C_Kg_Representation.KGR2_1000_IS_1]: 'Einheit = 1000',

  [C_History_Operations.HSO1_NEW_RECORD_WAS_ADDED]: 'Neuer Eintrag wurde hinzugefügt',
  [C_History_Operations.HSO2_RECORD_WAS_EDITED]: 'Eintrag wurde bearbeitet',
  [C_History_Operations.HSO3_RECORD_WAS_DELETED]: 'Eintrag wurde gelöscht',
  [C_History_Operations.HSO4_CHILD_RECORD_WAS_DELETED]: 'Kinder-Eintrag wurde gelöscht',
  [C_History_Operations.HSO5_RECORD_WAS_COPIED]: 'Eintrag wurde kopiert',
  [C_History_Operations.HSO6_RECORD_WAS_RESTORED]: 'Eintrag wurde wiederhergestellt',

  [C_Tax_System.TAX1_INCLUDED_VAT]: 'Inkl. MwSt',
  [C_Tax_System.TAX2_EXCLUDED_VAT]: 'Exkl. MwSt',
  [C_Tax_System.TAX3_NO_VAT]: 'Keine MwSt',
  [C_Tax_System.TAX4_INDIVIDUAL_FOR_CUSTOMER]: 'Für jeden Kunden wählbar',

  [C_Discounts_And_Prices_Kind.DNP1_DISCOUNT_WAWI_PRICE_CASH]:
    'Rabatte WaWiAssist, Preise CashAssist',
  [C_Discounts_And_Prices_Kind.DNP2_DISCOUNT_WAWI_PRICE_WAWI]:
    'Rabatte WaWiAssist, Preise WaWiAssist (Standard)',
  [C_Discounts_And_Prices_Kind.DNP3_NO_DISCOUNT_PRICE_CASH]: 'Kein Rabatte, Preise CashAssist',
  [C_Discounts_And_Prices_Kind.DNP4_DISCOUNT_CASH_PRICE_WAWI]:
    'Rabatte CashAssist, Preise WaWiAssist',
  [C_Discounts_And_Prices_Kind.DNP5_DISCOUNT_CASH_PRICE_CASH]:
    'Rabatte CashAssist, Preise CashAssist',
  [C_Discounts_And_Prices_Kind.DNP6_DISCOUNT_SPECIAL_PRICE_CASH]: 'Sonderrabatt, Preise CashAssist',

  [C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER]: 'Vorgabe-Lieferschein',
  [C_Customer_Def_Order_Source.DO2_WEEK_AGO_ORDER]: 'Lieferschein vor einer Woche',
  [C_Customer_Def_Order_Source.DO3_LAST_ORDER]: 'Letzter lieferschein',
  [C_Customer_Def_Order_Source.DO4_EMPTY_ORDER]: 'Leerer lieferschein',
  [C_Debitor_Status.DS1_DEBITOR]: 'Debitor',
  [C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR]: 'Rechnung löst kein Debitor aus',
  [C_Debitor_Status.DS3_INTERNAL_INVOICE]: 'Interne Rechnung (für Filialen)',
  [C_Direct_Debit_Banks.B1_NO_BANK]: '<Kein Bankeinzug>',
  [C_Direct_Debit_Banks.B2_BANK1_WITH_DTA]: 'Bank 1 mit DTA-Datei',
  [C_Direct_Debit_Banks.B3_BANK2_WITH_DTA]: 'Bank 2 mit DTA-Datei',
  [C_Direct_Debit_Banks.B4_MANUAL]: 'Manuelle Bank',

  [C_Special_Price_Type.SP1_PRICE]: 'Preis',
  [C_Special_Price_Type.SP2_DISCOUNT]: 'Rabatt',
  [C_Special_Price_Type.SP3_PRICE_AND_DISCOUNT]: 'Preis und Rabatt',
  [C_Special_Price_Type.SP4_PRICE_IF_QUANTITY_MORE]: 'Preis, wenn Menge mehr als..',
  [C_Special_Price_Type.SP5_DISCOUNT_IF_QUANTITY_MORE]: 'Rabatt, wenn Menge mehr als..',
  [C_Special_Price_Type.SP6_PRICE_AND_DISCOUNT_IF_QUANTITY_MORE]:
    'Preis und Rabatt, wenn Menge mehr als..',
  [C_Booking_Type.BO1_BOOKING_DATE_CREDIT]: 'Buchung nach Gutschriftsdatum',
  [C_Booking_Type.BO2_BOOKING_PROCESSING_DATE]: 'Buchung nach Verarbeitungsdatum',

  [Dict_Linked_Records_Names.CUSTOMERS]: 'Kunden',
  [Dict_Linked_Records_Names.ARTICLES]: 'Artikel',
  [Dict_Linked_Records_Names.PRODUCTION_REPORTS]: 'Produktionslisten',
  [Dict_Linked_Records_Names.PROFILES]: 'Profile',
  [Dict_Linked_Records_Names.CUSTOMER_PROFILES]: 'Kundenprofile',
  [Dict_Linked_Records_Names.INVOICES]: 'Rechnungen',
  [Dict_Linked_Records_Names.OFFERS]: 'Angebote',
  [Dict_Linked_Records_Names.ORDERS]: 'Bestellungen',
  [Dict_Linked_Records_Names.DRAFT_ORDERS]: 'Bestellungsentwurf',
  [Dict_Linked_Records_Names.DRAFT_OFFERS]: 'Angebotsentwurf',

  [Dict_Linked_Records_Fields.NAME]: 'Name',
  [Dict_Linked_Records_Fields.CUSTOMER_NO]: 'Kunden-Nr.',
  [Dict_Linked_Records_Fields.INVOICE_NO]: 'Rechnung-Nr.',
  [Dict_Linked_Records_Fields.OFFER_NO]: 'Angebot-Nr.',
  [Dict_Linked_Records_Fields.ARTICLE_NO]: 'Artikel-Nr.',
  [Dict_Linked_Records_Fields.PROFILE_ID]: 'Profile-Nr.',
  [Dict_Linked_Records_Fields.DELIVERY_NOTE_NO]: 'Lieferschein-Nr.',
  [Dict_Linked_Records_Fields.DATE]: 'Datum',

  [C_Invoice_Deletion_Possibility.IDP0_POSSIBLE]: '',
  [C_Invoice_Deletion_Possibility.IDP1_PROHIBITED_BECAUSE_OF_NON_CASH_PAYMENT]:
    'Diese Rechnung ist bereits als bezahlt verbucht. Löschung ist nicht möglich.',
  [C_Invoice_Deletion_Possibility.IDP2_PROHIBITED_BECAUSE_OF_PAY_NET_PAYMENT]:
    'Diese Rechnung wird von PayNet bearbeitet. Löschung ist nicht möglich.',

  [C_Email_Tracking_Event_Type.EVT_SEND]: 'Gesendet',
  [C_Email_Tracking_Event_Type.EVT_DELIVERY]: 'Zustellung',
  [C_Email_Tracking_Event_Type.EVT_COMPLAINT]: 'Beschwerde',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_PERMANENT]: 'Permanent fehlgeschlagen',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_TRANSIENT]: 'Vorübergehend fehlgeschlagen',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_UNDETERMINED]: 'Unbestimmt fehlgeschlagen',
  [C_Email_Tracking_Event_Type.EVT_UNKNOWN]: 'Unbekannt',
});
export const enumsEn = t({
  [C_Country.CO1_SWITZERLAND]: 'Switzerland',
  [C_Country.CO2_GERMANY]: 'Germany',
  [C_Country.CO3_AUSTRIA]: 'Austria',
  [C_Country.CO4_MALDIVES]: 'Maldives',
  [C_Country.CO5_LUXEMBOURG]: 'Luxembourg',

  [C_Discount_Kind.DK1_NO_DISCOUNT]: 'No discount',
  [C_Discount_Kind.DK2_ALL_ARTICLES]: 'Discount for all articles',
  [C_Discount_Kind.DK3_ARTICLE_GROUPS]: 'Discount for product groups',
  [C_Discount_Kind.DK4_PRICE_REDUCE]:
    'Discount in order (article price is reduced by the discount)',
  [C_Discount_Kind.DK5_QUANTITY_DISCOUNT]: 'Quantity discount on invoice amount (all articles)',
  [C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS]:
    'Quantity discount on invoice amount (product groups)',

  [C_Sale_Unit.SU1_PIECE]: 'Piece',
  [C_Sale_Unit.SU2_100G]: '100g',
  [C_Sale_Unit.SU3_1000G]: '1000g',
  [C_Sale_Unit.SU4_PIECE_AND_WEIGTH]: 'Piece and weight',
  [C_Sale_Unit.SU5_OPEN_AMOUNT]: 'Open amount',
  [C_Sale_Unit.SU6_OPEN_AMOUNT_MINUS]: 'Open amount (minus)',

  [C_Freezer_Condition.FC1_MINUS_18]: 'store at max. -18° C',
  [C_Freezer_Condition.FC2_PLUS_5]: 'store at max. +5° C',

  [C_Vat_Code.VT1_NORMAL]: 'VAT normal rate',
  [C_Vat_Code.VT2_REDUCED]: 'VAT reduced rate',
  [C_Vat_Code.VT3_SPECIAL1]: 'VAT special 1',
  [C_Vat_Code.VT4_SPECIAL2]: 'VAT special 2',
  [C_Vat_Code.VT5_ZERO]: 'VAT 0%',

  [C_Order_Type.OT1_ORDER]: 'Order',
  [C_Order_Type.OT2_ADDITIONAL_ORDER]: 'Additional order',
  [C_Order_Type.OT3_PICKING_LIST]: 'Picking list',
  [C_Order_Type.OT4_RETURN]: 'Return',
  [C_Order_Type.OT5_CREDIT_ORDER]: 'Credit order',
  [C_Order_Type.OT6_SPECIAL_ORDER]: 'Special order',
  [C_Order_Type.OT7_INTERNET_ORDER]: 'Internet order',
  [C_Order_Type.OT8_HOGASHOP]: 'Hogashop order',
  [C_Order_Type.OT9_CATERING]: 'Catering',
  [C_Order_Type.OT11_CASHASSIST_ORDER]: 'CashAssist order',
  [C_Order_Type.OT12_CASHASSIST_REDISCOUNT]: 'CashAssist rediscount',
  [C_Order_Type.OT13_CASHASSIST_OUTPUT]: 'CashAssist output',
  [C_Order_Type.OT14_CASHASSIST_RETURN]: 'CashAssist return',
  [C_Order_Type.OT15_CASHASSIST_TRANSFER]: 'CashAssist transfer',
  [C_Order_Type.OT16_CASHASSIST_ADDITIONAL_ORDER]: 'CashAssist additional order',
  [C_Order_Type.OT20_CASHASSIST_CUSTOMER_ORDER]: 'CashAssist customer order',
  [C_Order_Type.OT21_CASHASSIST_INVOICE]: 'CashAssist invoice',
  [C_Order_Type.OT22_CASHASSIST_CREDIT_CUSTOMER]: 'CashAssist credit customer order',

  [C_Invoice_Interval.II1_MONTHLY]: 'Monthly invoice',
  [C_Invoice_Interval.II2_MONTHLY2]: 'Monthly invoice 2',
  [C_Invoice_Interval.II3_MONTHLY3]: 'Monthly invoice 3',
  [C_Invoice_Interval.II4_MONTHLY4]: 'Monthly invoice 4',
  [C_Invoice_Interval.II5_HALF_MONTHLY]: 'Half-monthly invoice',
  [C_Invoice_Interval.II6_HALF_WEEKLY]: 'Weekly invoice',
  [C_Invoice_Interval.II7_MANUAL]: 'Manual',
  [C_Invoice_Interval.II8_DIRECT_CASH]: 'Direct cash invoice',
  [C_Invoice_Interval.II9_DIRECT_DEBITOR]: 'Direct debitor invoice',
  [C_Invoice_Interval.II10_NO_INVOICE_INTERNAL]: '(No invoice) for internal customers',

  [C_Customer_Address_Kind.CAK1_COMPANY]: 'Company',
  [C_Customer_Address_Kind.CAK2_COMPANY_WITH_DELIVERY_ADDRESS]: 'Company with delivery address',
  [C_Customer_Address_Kind.CAK3_PRIVATE_PERSON]: 'Personal',

  [C_Besr_Format.BF1_HSSOFT]: 'HS-Soft',
  [C_Besr_Format.BF2_ABACUS]: 'Abacus',
  [C_Besr_Format.BF3_ABACUS2]: 'Abacus - 2',

  [C_Procurement_Product_Kind.PPK0_NOT_AVAILABLE]: 'Not available group',
  [C_Procurement_Product_Kind.PPK1_MERCHANDISE_ARTICLE]: 'Merchandise article',
  [C_Procurement_Product_Kind.PPK2_OWN_PRODUCED_ARTICLE]: 'Own produced article',
  [C_Procurement_Product_Kind.PPK3_RAW_MATERIAL]: 'Raw material for branch',

  [C_Report_Group.RG1_PRODUCTION_PRODUCTION]: 'Production',
  [C_Report_Group.RG2_CUSTOMER]: 'Customer',
  [C_Report_Group.RG3_ARTICLE]: 'Article',
  [C_Report_Group.RG4_DEFAULT_ORDER]: 'Default order',
  [C_Report_Group.RG5_ORDER]: 'Order',
  [C_Report_Group.RG6_INVOICE]: 'Invoice',
  [C_Report_Group.RG7_DEBITOR]: 'Debitor',
  [C_Report_Group.RG8_PRODUCTION_FORWARDING_LISTS]: 'Forwarding list',
  [C_Report_Group.RG9_PRODUCTION_CROSSTABLE]: 'Cross-table report',
  [C_Report_Group.RG10_OFFER]: 'Offer',
  [C_Report_Group.RG11_PRODUCTION_SMARTSCALE]: 'Dough List (smartScale)',
  [C_Report_Group.RG12_PROCUREMENT]: 'Procurement',
  [C_Report_Group.RG13_PROCUREMENT_GOODS]: 'Procurement goods',
  [C_Report_Group.RG14_PROCUREMENT_SUPPLIERS]: 'Procurement suppliers',

  [C_Report.R0_USER_REPORT]: '',
  [C_Report.R1_BAKING_LIST_2_COLUMNS]: 'Baking list (2 columns)',
  [C_Report.R3_ADDRESS_LIST_A4_LANDSCAPE]: 'Address list A4 landscape',
  [C_Report.R4_FACTSHEET]: 'Fact sheet',
  [C_Report.R5_PHONES_LIST]: 'Phone list A4 landscape',
  [C_Report.R6_ARTICLE_LIST]: 'Article list',
  [C_Report.R7_WEEKLY_ORDERING_LIST]: 'Weekly ordering list',
  [C_Report.R8_DELIVERY_NOTE_A4_WITH_PRICE]: 'Delivery note A4 with price',
  [C_Report.R9_INVOICE_A4]: 'Invoice A4',
  [C_Report.R10_CUSTOMERS_DEBT_NOTIFIER]: 'Reminder about the debt',
  [C_Report.R11_CROSSTABLE_REPORT_A4_LANDSCAPE]: 'Crosstable report A4 landscape',
  [C_Report.R12_LABEL]: 'Label (LabelAssist)',
  [C_Report.R14_BAKING_LIST_WITH_RECIPE_2_COLUMNS]: 'Baking list with recipe (2 columns)',
  [C_Report.R15_FORWARDING_LIST_CUSTOMER_ARTICLES]: 'Forwarding list (Customer/Articles)',
  [C_Report.R16_DELIVERY_NOTE_A4_WITHOUT_PRICE]: 'Delivery note A4 without price',
  [C_Report.R17_INVOICE_SUMMARY]: 'Invoice summary',
  [C_Report.R18_INVOICE_PAYMENTSLIP]: 'Payment slip',
  [C_Report.R19_INVOICE_A4_DETAILED]: 'Invoice A4 detailed',
  [C_Report.R20_FORWARDING_LIST_ARTICLE_CUSTOMERS]: 'Forwarding list (Article/Customers)',
  [C_Report.R21_INVOICE_JOURNAL]: 'Invoice journal A4 landscape',
  [C_Report.R22_BESR_IMPORT_JOURNAL_A4_LANDSCAPE]: 'ISO (CAMT.054) Import Journal',
  [C_Report.R23_DEBITOR_JOURNAL_A4_LANDSCAPE]: 'Debitor journal',
  [C_Report.R24_PAYMENT_JOURNAL_A4_LANDSCAPE]: 'Payments journal',
  [C_Report.R25_BAKING_LIST_2_COLUMNS_COMBINED]: 'Baking list combined (2 columns)',
  [C_Report.R26_BAKING_LIST_WITH_RECIPE_A4]: 'Baking list with recipe (A4)',
  [C_Report.R27_BAKING_LIST_WITH_RECIPE_A5]: 'Baking list with recipe (A5)',
  [C_Report.R28_WEEKLY_ORDERING_LIST_BLANK_QUANTITY]: 'Weekly ordering list (blank quantity)',
  [C_Report.R29_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE]: 'Delivery note A5 landscape with price',
  [C_Report.R30_DELIVERY_NOTE_A5_LANDSCAPE_WITHOUT_PRICE]:
    'Delivery note A5 landscape without price',
  [C_Report.R31_INVOICE_A4_ARTICLE_GROUPING]: 'Invoice A4 (article grouping)',
  [C_Report.R32_TOURLIST]: 'Tour list',
  [C_Report.R33_PRICE_LIST_FOR_CUSTOMER]: 'Pricelist for customer',
  [C_Report.R34_BAKING_LIST_A4]: 'Baking list (1 column)',
  [C_Report.R35_EXTENDED_PAYMENT_JOURNAL]: 'Payments journal detailed',
  [C_Report.R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES]: 'Article list (all prices)',
  [C_Report.R37_OFFER_A4]: 'Offer A4',
  [C_Report.R38_OFFER_A4_ARTICLE_GROUPING]: 'Offer A4 (article grouping)',
  [C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING]: 'Collective invoice A4 (customer grouping)',
  [C_Report.R40_DELIVERY_NOTE_A4_OVERVIEW]: 'Delivery A4 overview',
  [C_Report.R41_BAKING_LIST_A4_DETAILED]: 'Baking list detailed (1 column)',
  [C_Report.R42_FORWARDING_LIST_CUSTOMER_ARTICLES_ONE_PER_PAGE]:
    'Forwarding list (Customer/Articles) separate pages',
  [C_Report.R43_DELIVERY_NOTE_A4_WITH_PRICE_AND_DISCOUNT]:
    'Delivery note A4 with price and discount',
  [C_Report.R44_DELIVERY_NOTE_A5_LANDSCAPE_WITH_PRICE_AND_DISCOUNT]:
    'Delivery note A5 landscape with price and discount',
  [C_Report.R45_SHIPPING_LABEL_ADDRESS_ONLY]: 'Shipping label address only',
  [C_Report.R46_SHIPPING_LABEL_FOR_ORDERED_ARTICLE]: 'Shipping label for ordered articles',
  [C_Report.R47_WEEKLY_ORDERINGLIST_BLANK_QUANTITY_BLANK_DATE]:
    'Weekly ordering list (blank quantity, blank date)',
  [C_Report.R48_OFFER_A4_WITH_IMAGES]: 'Offer A4 with images',
  [C_Report.R49_BAKING_LIST_2_COLUMNS_SMART_SCALE]: 'Baking list 2 columns (smartScale)',
  [C_Report.R50_ARTICLE_LIST_WITH_IMAGES]: 'Article list with images',
  [C_Report.R51_DELIVERY_NOTES_IN_PRODUCTION]: 'Delivery notes',
  [C_Report.R52_DELIVERY_OVERVIEW]: 'Delivery overview',
  [C_Report.R53_PROCUREMENT_ORDER_A4]: 'Procurement order A4',
  [C_Report.R54_PROCUREMENT_PROJECT_A4]: 'Procurement project A4',
  [C_Report.R55_DELIVERY_NOTE_A4_WITH_PRICE_AND_ORIGIN]: 'Delivery note A4 with price and origin',
  [C_Report.R56_BAKING_LIST_VERSIONS_DIFFERENCE]: 'Backing list with difference',
  [C_Report.R57_BAKING_LIST_RAWMATERIALS_2_COLUMNS]:
    'Backing list Raw material -> recipe (2 columns)',
  [C_Report.R58_BAKING_LIST_VERSIONS_DIFFERENCE_WITH_RECIPE]: 'Backing list difference with recipe',
  [C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR]: 'Payment slip with QR',
  [C_Report.R60_STOCK_LIST]: 'Stock list',
  [C_Report.R61_STOCK_VALUE]: 'Stock value',
  [C_Report.R62_DELIVERY_NOTE_PARTIAL_DELIVERY_A4_WITH_PRICE]:
    'Delivery note (partial delivery) A4 with price',
  [C_Report.R63_PROCUREMENT_SUPPLIER_GOODS_A4]: 'Ordered list A4',
  [C_Report.R64_CUSTOMERS_DEBT_NOTIFIER_WITH_INVOICE_AND_PAYMENTSLIP]:
    'Reminder about the debt with invoice and payment slip',
  [C_Report.R65_CROSSTABLE_REPORT_A4_PORTRAIT]: 'Crosstable report A4 portrait',
  [C_Report.R66_BAKING_LIST_FOR_CAKES_AND_PIES]: 'Baking list for cakes and pies',
  [C_Report.R67_CUSTOMER_PAYMENT_INFO_OVERVIEW]: 'Payment info overview',
  [C_Report.R68_FORWARDING_LIST_ARTICLE_CUSTOMERS_VERSIONS_DIFFERENCE]:
    'Forwarding list with difference (Article/Customers)',
  [C_Report.R_NOT_TO_PRINT]: '<do not print>',

  [C_Production_Article_Sorting_Kind.PASK1_DOUGH_ARTICLE_NO]: 'Dough belonging/Article No.',
  [C_Production_Article_Sorting_Kind.PASK2_ARTICLE_NO]: 'Article No.',
  [C_Production_Article_Sorting_Kind.PASK3_DESCRIPTION]: 'Description',
  [C_Production_Article_Sorting_Kind.PASK4_GROUP_ARTICLE_NO]: 'Group/Article No.',
  [C_Production_Article_Sorting_Kind.PASK5_GROUP_DESCRIPTION]: 'Group/Description',
  [C_Production_Article_Sorting_Kind.PASK6_CUSTOMER_NO_DELIVERY_NO_ARTICLE_NO]:
    'Customer No./Delivery No./Article No.',
  [C_Production_Article_Sorting_Kind.PASK7_ARTICLE_NO_SETUP_STATION]: 'Article No./Set-up station',
  [C_Production_Article_Sorting_Kind.PASK8_DELIVERY_TIME]: 'Delivery time',

  [C_Production_Customer_Sorting_Kind.PCSK1_CUSTOMER_NO]: 'Customer No.',
  [C_Production_Customer_Sorting_Kind.PCSK2_CUSTOMER_NAME]: 'Customer name',

  [C_Attached_Orders_Action.AOA0_UNBIND]: 'Unbind invoiced orders',
  [C_Attached_Orders_Action.AOA1_DELETE]: 'Delete invoiced orders',

  [C_Ca_Visibility_Status.CAVS1_ALWAYS]: 'Always',
  [C_Ca_Visibility_Status.CAVS2_ONLY_CR_MODE]: 'Only CashRegister mode',
  [C_Ca_Visibility_Status.CAVS3_ONLY_ORDER_MODE]: 'Nur Bestellmodus',
  [C_Ca_Visibility_Status.CAVS4_DISABLED]: 'Disabled',

  [C_Rounding_Method.RM1_NOT_USED]: 'Not used',
  [C_Rounding_Method.RM2_TO_0_05]: 'Round to .05',
  [C_Rounding_Method.RM3_TO_0_01]: 'Round to .01',
  [C_Rounding_Method.RM4_TO_0_001]: 'Round to .001',
  [C_Rounding_Method.RM5_TO_0_1]: 'Round to .1',

  [C_Order_Positions_Sorting_Kind.OPS1_ARTICLE_NO]: 'Article No.',
  [C_Order_Positions_Sorting_Kind.OPS2_DESCRIPTION]: 'Description',
  [C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL]: 'Original',

  [C_Kg_Representation.KGR1_1_IS_1]: 'Sale unit = 1',
  [C_Kg_Representation.KGR2_1000_IS_1]: 'Sale unit = 1000',

  [C_History_Operations.HSO1_NEW_RECORD_WAS_ADDED]: 'New record has been added',
  [C_History_Operations.HSO2_RECORD_WAS_EDITED]: 'Record has been edited',
  [C_History_Operations.HSO3_RECORD_WAS_DELETED]: 'Record has been deleted',
  [C_History_Operations.HSO4_CHILD_RECORD_WAS_DELETED]: 'Child record has been deleted',
  [C_History_Operations.HSO5_RECORD_WAS_COPIED]: 'Record has been copied',
  [C_History_Operations.HSO6_RECORD_WAS_RESTORED]: 'Entry was restored',

  [C_Tax_System.TAX1_INCLUDED_VAT]: 'Incl. VAT',
  [C_Tax_System.TAX2_EXCLUDED_VAT]: 'Excl. VAT',
  [C_Tax_System.TAX3_NO_VAT]: 'Without VAT',
  [C_Tax_System.TAX4_INDIVIDUAL_FOR_CUSTOMER]: 'Individual for each customer',

  [C_Discounts_And_Prices_Kind.DNP1_DISCOUNT_WAWI_PRICE_CASH]:
    'Discount WaWiAssist, price CashAssist',
  [C_Discounts_And_Prices_Kind.DNP2_DISCOUNT_WAWI_PRICE_WAWI]:
    'Discount WaWiAssist, price WaWiAssist(Standard)',
  [C_Discounts_And_Prices_Kind.DNP3_NO_DISCOUNT_PRICE_CASH]: 'No discount, price CashAssist',
  [C_Discounts_And_Prices_Kind.DNP4_DISCOUNT_CASH_PRICE_WAWI]:
    'Discount CashAssist, price WaWiAssist',
  [C_Discounts_And_Prices_Kind.DNP5_DISCOUNT_CASH_PRICE_CASH]:
    'Discount CashAssist, price CashAssist',
  [C_Discounts_And_Prices_Kind.DNP6_DISCOUNT_SPECIAL_PRICE_CASH]:
    'Special discount, price CashAssist',

  [C_Customer_Def_Order_Source.DO1_DEFAULT_ORDER]: 'Default delivery note',
  [C_Customer_Def_Order_Source.DO2_WEEK_AGO_ORDER]: 'Delivery note from one week ago',
  [C_Customer_Def_Order_Source.DO3_LAST_ORDER]: 'Last delivery note',
  [C_Customer_Def_Order_Source.DO4_EMPTY_ORDER]: 'Empty delivery note',
  [C_Debitor_Status.DS1_DEBITOR]: 'Debtor',
  [C_Debitor_Status.DS2_INVOICE_DOES_NOT_INITIATE_DEBITOR]: 'Invoice does not initiate debt',
  [C_Debitor_Status.DS3_INTERNAL_INVOICE]: 'Internal invoice (for branches)',
  [C_Direct_Debit_Banks.B1_NO_BANK]: '<No bank>',
  [C_Direct_Debit_Banks.B2_BANK1_WITH_DTA]: 'Bank 1 with DTA file',
  [C_Direct_Debit_Banks.B3_BANK2_WITH_DTA]: 'Bank 2 with DTA file',
  [C_Direct_Debit_Banks.B4_MANUAL]: 'Manual bank',

  [C_Special_Price_Type.SP1_PRICE]: 'Price',
  [C_Special_Price_Type.SP2_DISCOUNT]: 'Discount',
  [C_Special_Price_Type.SP3_PRICE_AND_DISCOUNT]: 'Price and discount',
  [C_Special_Price_Type.SP4_PRICE_IF_QUANTITY_MORE]: 'Price, if quantity more than..',
  [C_Special_Price_Type.SP5_DISCOUNT_IF_QUANTITY_MORE]: 'Discount, if quantity more than..',
  [C_Special_Price_Type.SP6_PRICE_AND_DISCOUNT_IF_QUANTITY_MORE]:
    'Price and discount, if quantity more than..',
  [C_Booking_Type.BO1_BOOKING_DATE_CREDIT]: 'Booking date credit',
  [C_Booking_Type.BO2_BOOKING_PROCESSING_DATE]: 'Booking processing date',

  [Dict_Linked_Records_Names.CUSTOMERS]: 'Customers',
  [Dict_Linked_Records_Names.ARTICLES]: 'Articles',
  [Dict_Linked_Records_Names.PRODUCTION_REPORTS]: 'Production reports',
  [Dict_Linked_Records_Names.PROFILES]: 'Profiles',
  [Dict_Linked_Records_Names.CUSTOMER_PROFILES]: 'Customer profiles',
  [Dict_Linked_Records_Names.INVOICES]: 'Invoices',
  [Dict_Linked_Records_Names.OFFERS]: 'Offers',
  [Dict_Linked_Records_Names.ORDERS]: 'Orders',
  [Dict_Linked_Records_Names.DRAFT_ORDERS]: 'Draft orders',
  [Dict_Linked_Records_Names.DRAFT_OFFERS]: 'Draft offers',

  [Dict_Linked_Records_Fields.NAME]: 'Name',
  [Dict_Linked_Records_Fields.CUSTOMER_NO]: 'Customer No.',
  [Dict_Linked_Records_Fields.INVOICE_NO]: 'Invoice No.',
  [Dict_Linked_Records_Fields.OFFER_NO]: 'Offer No.',
  [Dict_Linked_Records_Fields.ARTICLE_NO]: 'Article No.',
  [Dict_Linked_Records_Fields.PROFILE_ID]: 'Profile No.',
  [Dict_Linked_Records_Fields.DELIVERY_NOTE_NO]: 'Delivery note No.',
  [Dict_Linked_Records_Fields.DATE]: 'Date',

  [C_Invoice_Deletion_Possibility.IDP0_POSSIBLE]: '',
  [C_Invoice_Deletion_Possibility.IDP1_PROHIBITED_BECAUSE_OF_NON_CASH_PAYMENT]:
    'This invoice is already booked as paid. Deletion not possible.',
  [C_Invoice_Deletion_Possibility.IDP2_PROHIBITED_BECAUSE_OF_PAY_NET_PAYMENT]:
    'This invoice is in processing by PayNet. Deletion is not possible.',

  [C_Email_Tracking_Event_Type.EVT_SEND]: 'Send',
  [C_Email_Tracking_Event_Type.EVT_DELIVERY]: 'Delivery',
  [C_Email_Tracking_Event_Type.EVT_COMPLAINT]: 'Complaint',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_PERMANENT]: 'Bounce permanent',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_TRANSIENT]: 'Bounce transient',
  [C_Email_Tracking_Event_Type.EVT_BOUNCE_UNDETERMINED]: 'Bounce undetermined',
  [C_Email_Tracking_Event_Type.EVT_UNKNOWN]: 'Unknown',
});
