import { filter, finalize, map, merge, Observable, switchMap, take, tap, zip } from 'rxjs';
import equal from 'fast-deep-equal/react';

import {
  C_Sale_Unit,
  C_Save_Operation_Status,
  C_Vat_Code,
  SaveArticleDataMutationVariables,
  Wa_ArticleSpecialPricesGridItemInput,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictPriceCategoriesRes,
} from '../../../../../shared/services/configsData/configsData.service';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  ArticleGeneralTabDataRes,
  articleService,
  SaveArticleDataRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState } from '../../../states/tabState.model';
import { articleTabLoadingService } from '../articleTababLoading.service';
import { TArticleSpecialPrices } from './components/specialPrices/specialPrices.component';
import { generateSpecialPricesWithOnlyUsablePrices } from '../../../loaders/generalTab.resolver';
import { resetTimeInDate } from '../../../../../shared/helpers/utils/utils.helper';
import { modeService } from '../../../../../shared/services/mode/mode.service';
import { artcileTabsSub } from '../articleCommonTabs.sub';

export const defaultCreateModeValues: Partial<ArticleGeneralTabDataRes> = {
  id: 'testId',
  articleNo: null,
  description: null,
  saleUnitId: C_Sale_Unit.SU1_PIECE,
  productionGroupId: null,
  marketingGroupId: null,
  shippingPointId: null,
  price1: null,
  price2: null,
  price3: null,
  price4: null,
  price5: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  isNoDiscount: false,
  note: null,
  isNotifyOnOrderStage: false,
  quantityPerLot: null,
  isActive: true,
  vatCode: C_Vat_Code.VT2_REDUCED,
};

class PubImpl extends Pub<IGeneralTabState> {
  recordData(dataToSave: IGeneralTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save(dataToSave?: IGeneralTabState['dataToSave']) {
    if (dataToSave) {
      this.emit('save', { dataToSave });
    } else {
      this.emit('save', {});
    }
  }
  initArticleSpecialPrices(articleSpecialPrices: IGeneralTabState['articleSpecialPrices']) {
    this.emit('initArticleSpecialPrices', {
      articleSpecialPrices,
      articleSpecialPricesBackup: structuredClone(articleSpecialPrices),
      selectedSpecialPricesPos: articleSpecialPrices?.[0],
    });
  }
  selectSpecialPricesPos(selectedSpecialPricesPos: IGeneralTabState['selectedSpecialPricesPos']) {
    this.emit('selectSpecialPricesPos', { selectedSpecialPricesPos });
  }
  updateSpecialPricesPos(selectedSpecialPricesPos: IGeneralTabState['selectedSpecialPricesPos']) {
    this.emit('updateSpecialPricesPos', { selectedSpecialPricesPos });
  }
  deleteSpecialPricesPos() {
    this.emit('deleteSpecialPricesPos', {});
  }
  saveSpecialPricesGrid(articleId: IGeneralTabState['articleId']) {
    this.emit('saveSpecialPricesGrid', { articleId });
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends artcileTabsSub<IGeneralTabState>() {
  protected actionHandlers(): Observable<IGeneralTabState> {
    return merge(
      this.selectedArticle(),
      this.save(),
      this.saveSpecialPricesGrid(),
      this.updateState(),
      this.updateSpecialPricesPos(),
      this.deleteSpecialPricesPos(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  private save(): Observable<IGeneralTabState> {
    return this.actionListener('save').pipe(
      filter(({ dataToSave }) => {
        return Boolean(Object.keys(dataToSave).length);
      }),
      tap(({ dataToSave }) => {
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });
        articleService.pub.editArticle({
          dataToSave,
        } as SaveArticleDataMutationVariables);
      }),
      finalize(() => generalTabState.pub.clearStream()),
    );
  }

  private updateSpecialPricesPos(): Observable<IGeneralTabState> {
    return this.actionListener('updateSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const foundedIdx = updatedState.articleSpecialPrices.findIndex(
          (item) => item.id === updatedState.selectedSpecialPricesPos?.id,
        );
        if (updatedState.selectedSpecialPricesPos) {
          if (foundedIdx !== -1) {
            updatedState.articleSpecialPrices.splice(
              foundedIdx,
              1,
              updatedState.selectedSpecialPricesPos,
            );
          } else updatedState.articleSpecialPrices.push(updatedState.selectedSpecialPricesPos);
          updatedState.articleSpecialPrices = this.findActualSpecialPrice(
            updatedState.articleSpecialPrices,
          );
        }
        return updatedState;
      }),
    );
  }

  findActualSpecialPrice(articleSpecialPrices: TArticleSpecialPrices): TArticleSpecialPrices {
    const actualPrices = articleSpecialPrices?.filter((row) =>
      this.isSpecialPriceRowCanBeActual(row),
    );
    if (actualPrices?.length) {
      if (actualPrices?.length === 1) {
        const actual = actualPrices[0];
        return this.setActualToRow(articleSpecialPrices, actual?.id);
      }
      const maxFromDate = actualPrices.reduce((res, { fromDate }) => {
        if (!fromDate) {
          return res;
        }
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from > res) {
          return from;
        }
        return res;
      }, 0);

      const caunterRowsWithMaxFromDate = actualPrices.reduce((caunter, { fromDate }) => {
        if (!fromDate) {
          return caunter;
        }
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from === maxFromDate) {
          return caunter + 1;
        }
        return caunter;
      }, 0);

      if (caunterRowsWithMaxFromDate === 1) {
        const actual = actualPrices.find(({ fromDate }) => {
          if (fromDate) {
            const from = resetTimeInDate(new Date(fromDate))?.getTime();
            return from === maxFromDate;
          }
        });
        return actual?.id
          ? this.setActualToRow(articleSpecialPrices, actual.id as string)
          : articleSpecialPrices;
      }

      const theBiggestId = actualPrices.reduce((initId, { id: idForCheck, fromDate }) => {
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from !== maxFromDate) {
          return initId;
        }
        const id = this.deletePrefixNew(initId);
        const numberId = this.deletePrefixNew(idForCheck);
        if (id > numberId) {
          return initId;
        } else {
          return idForCheck;
        }
      }, '0');

      return theBiggestId
        ? this.setActualToRow(articleSpecialPrices, String(theBiggestId))
        : articleSpecialPrices;
    }

    return articleSpecialPrices;
  }

  private deletePrefixNew(initId: number | string): number {
    const stringId = String(initId);
    if (stringId?.startsWith('new_')) {
      const numberId = Number(stringId?.replace('new_', ''));
      return numberId;
    }
    const numberId = Number(initId);
    return numberId;
  }

  private isSpecialPriceRowCanBeActual(row: TArticleSpecialPrices[number]): boolean {
    const { fromDate, toDate } = row;
    const current = resetTimeInDate(new Date())?.getTime();
    let from: number | null = null;
    let to: number | null = null;
    if (fromDate) {
      from = resetTimeInDate(new Date(fromDate))?.getTime();
    }
    if (toDate) {
      to = new Date(toDate)?.setHours(23, 59, 59, 999);
    }
    if (!from && !to) {
      return false;
    }
    if (from && to) {
      return current > from && current < to;
    }
    if (!from) {
      return current < (to as number);
    }
    // case when  to ===  null
    return current > from;
  }

  private setActualToRow(
    articleSpecialPrices: TArticleSpecialPrices,
    id: string,
  ): TArticleSpecialPrices {
    return articleSpecialPrices?.map((v) => {
      const { id: idForCheck } = v;
      if (idForCheck === id) {
        return {
          ...v,
          isActual: true,
        };
      } else {
        return {
          ...v,
          isActual: false,
        };
      }
    });
  }

  private deleteSpecialPricesPos(): Observable<IGeneralTabState> {
    return this.actionListener('deleteSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IGeneralTabState['selectedSpecialPricesPos'] = null;
        const updatedSpecialPrices = updatedState.articleSpecialPrices?.filter((pos, i, arr) => {
          if (pos?.id === state.selectedSpecialPricesPos?.id) {
            const nextPos = arr?.[i + 1];
            const prevPos = arr?.[i - 1];
            if (i === 0 && arr!.length > 1) {
              selectedPos = nextPos;
            }
            if (i !== 0) {
              selectedPos = nextPos || prevPos || null;
            }
            return false;
          } else return true;
        });
        if (updatedSpecialPrices?.length) {
          updatedState.articleSpecialPrices = this.findActualSpecialPrice(updatedSpecialPrices);
        } else {
          updatedState.articleSpecialPrices = updatedSpecialPrices;
        }
        updatedState.selectedSpecialPricesPos = selectedPos;
        return updatedState;
      }),
    );
  }

  saveSpecialPricesGrid(): Observable<IGeneralTabState> {
    return this.actionListener('saveSpecialPricesGrid').pipe(
      tap((state) => {
        const updatedState = structuredClone(state);
        const { articleSpecialPrices, articleSpecialPricesBackup, articleId } = updatedState;
        if (!equal(articleSpecialPrices, articleSpecialPricesBackup)) {
          articleService.sub
            .editArticle()
            .pipe(
              responseHandler<SaveArticleDataRes | undefined>({
                success: () => 'article.article_saved',
                customErrorHandler: () => 'article.article_not_saved',
              }),
              filter((v) => v !== undefined),
              take(1),
            )
            .subscribe((res) => {
              if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
                const { updatedGridItem } = res;
                articleListState.pub.updateArticle(updatedGridItem!);
              }
            });
          const preparedData = articleSpecialPrices
            .filter(({ fromDate, toDate }) => fromDate || toDate)
            .map(({ id, isActual: _, ...rest }) => ({
              ...rest,
              ...(!id.includes('new_') && { id }),
            })) as Wa_ArticleSpecialPricesGridItemInput[];
          articleService.pub.editArticle({
            dataToSave: { id: articleId, articleSpecialPrices: preparedData },
          } as SaveArticleDataMutationVariables);
          this.stream$.next({
            ...updatedState,
            articleSpecialPricesBackup: structuredClone(updatedState.articleSpecialPrices),
            action: 'internalUpdate',
          });
        }
      }),
      finalize(() => generalTabState.pub.clearStream()),
      filter(() => false),
    );
  }

  private selectedArticle(): Observable<IGeneralTabState> {
    return articleListState.sub.state().pipe(
      filter(({ action }) => {
        return action === 'selectArticle' || action === 'articleList' || action === 'filter';
      }),
      filter(({ selectedArticle }) => {
        const state = this.stream$.getValue();
        if (!selectedArticle || !selectedArticle?.id) {
          state.defaultValues = {};
          this.articleIdNotExist$.next(true);
        }
        return (
          typeof selectedArticle?.id === 'string' && state.defaultValues.id !== selectedArticle?.id
        );
      }),
      tap(() => modeService.pub.mode('edit')),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        const details = zip(
          articleService.sub.articleGeneralTabData(),
          configsData.sub.dictPriceCategories(),
        ).pipe(
          responseHandler<GeneralAndPriceCategoriesRes>({
            customErrorHandler: () => 'article.tab_not_loaded',
            errorReturnType: [{ ...defaultCreateModeValues }, []],
          }),
          take(1),
          map(([{ articleSpecialPrices: asp = [], ...rest }, priceCategories]) => {
            const articleSpecialPrices = generateSpecialPricesWithOnlyUsablePrices(
              asp,
              priceCategories,
            );
            state.defaultValues = rest;
            state.selectedSpecialPricesPos = null;
            state.articleSpecialPrices = articleSpecialPrices;
            state.selectedSpecialPricesPos = articleSpecialPrices?.[0];
            state.articleSpecialPricesBackup = structuredClone(articleSpecialPrices);
            articleTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        articleService.pub.articleGeneralTabData({ id });
        configsData.pub.common(['dictPriceCategories']);
        return details;
      }),
    );
  }

  private updateState(): Observable<IGeneralTabState> {
    return this.actionListener(['selectSpecialPricesPos']);
  }
}

class GeneralTabState extends State<IGeneralTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const initSpecialPricePosition = {
  fromDate: null,
  toDate: null,
  isActual: false,
  price1: null,
  price2: null,
  price3: null,
  price5: null,
  price4: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  description: '',
  id: null,
};

export const generalTabState = new GeneralTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
  articleSpecialPrices: [],
  selectedSpecialPricesPos: null,
  articleSpecialPricesBackup: [],
  articleId: null,
});

export interface IGeneralTabState
  extends Pick<ITabState, 'defaultValues' | 'articleSpecialPrices'> {
  action:
    | undefined
    | 'list.selectedArticle'
    | 'recordData'
    | 'save'
    | 'saveSpecialPricesGrid'
    | 'initArticleSpecialPrices'
    | 'selectSpecialPricesPos'
    | 'deleteSpecialPricesPos'
    | 'updateSpecialPricesPos'
    | 'internalUpdate';
  selectedSpecialPricesPos: TArticleSpecialPrices[number] | null;
  dataToSave: ArticleGeneralTabDataRes | Record<string, any>;
  articleId: ArticleGeneralTabDataRes['id'] | null;
  articleSpecialPricesBackup: ITabState['articleSpecialPrices'];
}

type GeneralAndPriceCategoriesRes = [Partial<ArticleGeneralTabDataRes>, DictPriceCategoriesRes];
